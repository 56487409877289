import React, { ReactNode } from 'react'

import styled, { useTheme } from 'styled-components'

import { Paragraph } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'

const Container = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TitleContainer = styled.div`
  flex-shrink: 0;
  ${ResponsivePXValue('margin', '8px 0')}
`

const Seperator = styled.div<{ $color: string }>`
  flex-grow: 1;
  background: ${(props): string => props.$color};
  ${ResponsivePXValue('height', '1px')}
  ${ResponsivePXValue('margin', { mobile: '0 10px', tablet: '0 10px', desktop: '0 10px' })}
`

const SingleSeperator = styled.div`
  background: ${(props): string => props.color};
  ${ResponsivePXValue('width', { mobile: 'CALC(100% - 20px)', tablet: 'CALC(100% - 40px)', desktop: 'CALC(100% - 60px)' })}
  ${ResponsivePXValue('height', '1px')}
`

export interface DividerProps {
  children?: ReactNode
  color?: string
  className?: string
}

export function Divider({ children, color, className }: DividerProps): JSX.Element {

  const theme = useTheme()

  const sepColor = color || theme.colors.grey.athens

  return (
    <Container className={className}>
      <Choose>
        <When condition={!!children}>
          <Seperator $color={sepColor} />
          <TitleContainer>{children}</TitleContainer>
          <Seperator $color={sepColor} />
        </When>
        <Otherwise>
          <SingleSeperator color={sepColor} />
        </Otherwise>
      </Choose>
    </Container>
  )

}
