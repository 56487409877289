import React, { useState, useEffect } from 'react'

import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'
import { camelCase } from 'camel-case'
import update from 'react-addons-update'
import { useLocation, useNavigate } from 'react-router'
import { animated, useSpring } from 'react-spring'
import { snakeCase } from 'snake-case'
import styled, { useTheme } from 'styled-components'

import { NavigationPlugin } from '@api/local/NavigationPlugin'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Skeleton, SkeletonNode } from '@atoms/layout'
import { Label, SmallLoader } from '@atoms/notifications'
import { Heading, Paragraph, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { AggregationFragment, AggregationOptionFragment } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { FieldData, Form, useForm, TextInput } from '@molecules/inputs'
import { AggregationTypeEnum, ProductAttributeFilterInput } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

const Iconwrapper = styled.div`
  ${ResponsivePXValue('width', '20px')}
  ${ResponsivePXValue('height', '20px')}  
  ${ResponsivePXValue('margin-bottom', '5px')}
`

const Filters = styled.div`
  position: relative;
  ${ResponsivePXValue('padding', '0 16px')}
`

const Header = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '0 16px')}
  ${ResponsivePXValue('height', '54px')}
  ${ResponsivePXValue('gap', '8px')}
`

const MobileHeader = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '0 16px')}
  ${ResponsivePXValue('height', '54px')}
  ${ResponsivePXValue('gap', '8px')}
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  z-index: 1;
`

const RightHeader = styled.div``

const LeftHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${ResponsivePXValue('gap', '8px')}
`

const Close = styled.div`
  ${ResponsivePXValue('height', '16px')}
  ${ResponsivePXValue('width', '16px')}
`

const Container = styled.div`
  position: relative;
  height: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('width', 'CALC(100%)')}
  overflow-x: hidden;
`

const FilterHeading = styled.div`
  display: flex;
  justify-content:space-between;
  ${ResponsivePXValue('padding', '12px 16px')}
  background-color: ${(props): string => props.theme.colors.white.fantasy};

  .icon {
    ${ResponsivePXValue('width', '12px')}
    ${ResponsivePXValue('height', '12px')}
  }
`

const FilterGroup = styled.div`
  ${ResponsivePXValue('padding-bottom', { mobile: '0', tablet: '6px', desktop: '6px' })}

  .filter-heading {
    ${ResponsivePXValue('margin-bottom', '16px')}
    ${ResponsivePXValue('padding', '12px 16px')}
    background-color: ${(props): string => props.theme.colors.white.fantasy};
  }
  .filter-seperator {
    ${ResponsivePXValue('margin', '20px 0')}
  }
  .search{
    ${ResponsivePXValue('margin', '0 16px 16px 16px')}
    background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.white.pampas, 0.8)};
  }
  .searchBody {
    ${ResponsivePXValue('margin', '0 16px 0 0')}
  }
`

const Filter = styled.a<{ active: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  ${ResponsivePXValue('padding-top', '16px')}
  ${ResponsivePXValue('gap', '4px')}
  ${ResponsivePXValue('padding-bottom', '16px')}

  .text-item {
    margin: 0;
    ${ResponsivePXValue('margin-left', '4px')}
    ${(props): string => props.active ? 'font-weight: 600;' : ''}
    white-space: pre-wrap;
  }
  &:hover {
    .text-item {
      color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
    .check-box {
      border-color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
    .check {
      background-color: ${(props): string => props.theme.colors.blue.curiousBlue};
    }
  }
`

const CheckBox = styled.div<{ checked: string }>`
  ${ResponsivePXValue('width', '16px')}
  ${ResponsivePXValue('height', '16px')}
  ${ResponsivePXValue('border-radius', '2px')}
  ${(props): string => ResponsivePXValue('border', `1px solid ${props.checked ? props.theme.colors.green.greenVogue : props.theme.colors.grey.silver}`)}
  background-color: ${(props): string => props.checked ? props.theme.colors.green.greenVogue : props.theme.colors.white.pureWhite};
  flex-shrink: 0;

  .icon {
    ${ResponsivePXValue('width', '13px')}
    ${ResponsivePXValue('height', '13px')}
  }
`

const CurrentFiltersBody = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  ${ResponsivePXValue('gap', '5px')}
  ${ResponsivePXValue('padding', '12px 16px')}
`

const CurrentFilters = styled.div`
  width: 100%;
`

const CurrentHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '12px 16px')}
  background-color: ${(props): string => props.theme.colors.white.fantasy};

  .clear-filters {
    cursor: pointer;
  }
`

const LoadingOverlay = styled.div<{ active: boolean }>`
  display: ${(props): string => props.active ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.white.pureWhite, 0.5)};
`

const FilterLoaderContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '12px', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('height', { mobile: '12px', tablet: '16px', desktop: '16px' })}
`

const MenuContainer = styled(animated.div)`
  width: 100%;
  position: fixed;
  overflow: scroll;
  top: 0;
  z-index: 40;
  ${ResponsivePXValue('height', 'CALC(100% + 15px)')}
  ${ResponsivePXValue('padding', '0 0 0 40px')}
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.black.pureBlack, 0.77)};
`

const MobileFilterContainer = styled(animated.div)`
  position: relative;
  height: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('width', '100%')}
  overflow-x: hidden;
`

export interface ProductFilterInputs extends ProductAttributeFilterInput {
  [k: string]: any
}

export interface ProductFiltersProps {
  aggregations: Readonly<AggregationFragment[]>
  open?: boolean
  filters: ProductFilterInputs
  loading: boolean
  onFilterChange?: (filters: ProductFilterInputs) => void
  onToggleFilters?: () => void
}

interface ProductFiltersState {
  categorySearch: string
  brandSearch: string
  loadingFilter: { [k: string]: string } | null
  tabs: { [k: string]: boolean }
}

const DEFAULT_STATE: ProductFiltersState = {
  categorySearch: '',
  brandSearch: '',
  loadingFilter: null,
  tabs: {},
}

export function ProductFilters({ open, filters, aggregations, loading, onFilterChange, onToggleFilters }: ProductFiltersProps): JSX.Element {

  const [state, setState] = useState<ProductFiltersState>({ ...DEFAULT_STATE })
  const theme = useTheme()
  const navigate = useNavigate()
  const location = useLocation()
  const form = useForm()

  const uniqueAggregations: string[] = []
  const filteredAggregations = aggregations.filter((agg) => {
    if (!uniqueAggregations.includes(agg.attributeCode)) {
      uniqueAggregations.push(agg.attributeCode)
      return true
    }
    return false
  })

  const _handleClearFilters = (): void => {
    navigate(`${location.pathname}`)
  }

  const _handleBackgroundClose = (e: React.MouseEvent<HTMLDivElement>): void => {
    e.preventDefault()
    if (e.target === e.currentTarget) {
      onToggleFilters()
    }
  }

  const _handleFilter = (attributeCode: string, option: string, e?: React.MouseEvent<HTMLAnchorElement>): void => {
    e?.preventDefault()
    if (loading) {
      return
    }
    setState((prevState) => update(prevState, {
      loadingFilter: { $set: { [attributeCode]: option } },
    }))
    const group = filteredAggregations?.find((group) => group.attributeCode === attributeCode)
    const isMulti = group?.type === AggregationTypeEnum.MULTI_SELECT
    const newFilters = { ...filters }
    if (isMulti) {
      if (!newFilters[camelCase(attributeCode)]) {
        newFilters[camelCase(attributeCode)] = {
          in: [option],
        }
      } else if (newFilters[camelCase(attributeCode)].in.includes(option)) {
        newFilters[camelCase(attributeCode)].in.splice(newFilters[camelCase(attributeCode)].in.indexOf(option), 1)
        if (!newFilters[camelCase(attributeCode)].in.length) {
          delete newFilters[camelCase(attributeCode)]
        }
      } else {
        newFilters[camelCase(attributeCode)].in.push(option)
      }
    } else {
      if (newFilters?.[camelCase(attributeCode)]?.in?.[0] === option) {
        delete newFilters[camelCase(attributeCode)]
      } else {
        newFilters[camelCase(attributeCode)] = {
          in: [option],
        }
      }
    }
    onFilterChange(newFilters)
  }

  const _handleFieldsChange = (changedFields: FieldData[]) => {

    const newFilters = { ...filters }
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'categorySearch') {
          setState((prevState) => update(prevState, {
            categorySearch: { $set: field.value },
          }))
        } else if (name === 'brandSearch') {
          setState((prevState) => update(prevState, {
            brandSearch: { $set: field.value },
          }))
        } else if (name === 'price') {
          newFilters[camelCase('price')] = []
          newFilters[camelCase('price')].from = field.value[0]
          newFilters[camelCase('price')].to = field.value[1]
          onFilterChange(newFilters)
        }
      })
    })
  }

  const _handleToggleFilterSection = (attributeCode: string) => {
    const newTabs = { ...state.tabs }
    newTabs[attributeCode] = !state.tabs[attributeCode]
    setState((prevState) => update(prevState, {
      tabs: { $set: newTabs },
    }))
  }

  useEffect(() => {
    if (!loading) {
      setState((prevState) => update(prevState, {
        loadingFilter: { $set: null },
      }))
    }
  }, [aggregations])

  // Mobile only

  const sidebarContainerProps = useSpring({
    dspl: open ? 1 : 0,
    backgroundColor: open ? SiteHelper.getOpaqueColor(theme.colors.black.pureBlack, 0.25) : theme.colors.misc.transparent,
  })

  const containerProps = useSpring({
    right: open ? '0%' : '-100%',
  })

  useEffect(() => {
    NavigationPlugin.shared().preventScroll(open)
    if (!open) {
      document && enableBodyScroll(document as unknown as Document)
    } else {
      document && disableBodyScroll(document as unknown as Document, {
        allowTouchMove: (el: Element) => {
          while (el && el !== document.body) {
            if (el.classList.contains('scroll-lock-ignore')) {
              return true
            }
            el = el.parentElement
          }
        },
      })
    }
    return () => {
      NavigationPlugin.shared().preventScroll(false)
      document && enableBodyScroll(document as unknown as Document)
    }
  }, [open])

  let filterGroup: AggregationFragment
  let option: AggregationOptionFragment

  const displayedFilters = { ...filters }
  delete displayedFilters.name
  const filterKeys = Object.keys(displayedFilters)
  let filterKey: string
  let filterValue: string
  let skeletonIndex

  const $filters = (
    <Container>
      <DeviceContainer desktop tablet>
        <Header>
          <LeftHeader>
            <Iconwrapper>
              <Icon className="icon" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.FILTER} />
            </Iconwrapper>
            <Title variant='t3' className='filter-title'>Filter</Title>
          </LeftHeader>
          <RightHeader>
            <DeviceContainer mobile>
              <Close onClick={onToggleFilters}>
                <Icon className=" icon close" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CLOSE} />
              </Close>
            </DeviceContainer>
          </RightHeader>
        </Header>
      </DeviceContainer>
      <DeviceContainer mobile>
        <MobileHeader>
          <LeftHeader>
            <Iconwrapper>
              <Icon className="icon" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.FILTER} />
            </Iconwrapper>
            <Title variant='t3' className='filter-title'>Filter</Title>
          </LeftHeader>
          <RightHeader>
            <DeviceContainer mobile>
              <Close onClick={onToggleFilters}>
                <Icon className=" icon close" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CLOSE} />
              </Close>
            </DeviceContainer>
          </RightHeader>
        </MobileHeader>
      </DeviceContainer>
      <If condition={!!filterKeys.length}>
        <CurrentFilters>
          <CurrentHeader>
            <Heading
              variant='h8'
              className='filter-heading'
              color={theme.colors.green.greenVogue}>
              Currently applied
            </Heading>
            <Paragraph
              variant='p2'
              decoration='underline'
              className='clear-filters'
              color={theme.colors.orange.piper}
              onClick={_handleClearFilters} >
              Clear filters
            </Paragraph>
          </CurrentHeader>
          <CurrentFiltersBody>
            <For each='filterKey' of={filterKeys}>
              <If condition={filterKey === 'price'}>
                <Label
                  radius={true}
                  key={`${filterKey}-${filters[filterKey].from}`}
                  color={theme.colors.green.bottleGreen}
                  backgroundColor={theme.colors.yellow.dolly}
                  onClose={() => _handleFilter(snakeCase(filterKey), filters[filterKey].from)}>
                  {`Price : R${filters[filterKey].from} - R${filters[filterKey].to}`}
                </Label>
              </If>
              <If condition={filterKey !== 'price' && !!filters[filterKey]?.in}>
                <For each='filterValue' of={filters[filterKey].in}>
                  <Label
                    radius={true}
                    key={`${filterKey}-${filterValue}`}
                    color={theme.colors.green.bottleGreen}
                    backgroundColor={theme.colors.yellow.dolly}
                    onClose={() => _handleFilter(snakeCase(filterKey), filterValue)}>
                    {filteredAggregations
                      ?.find((agg) => camelCase(agg.attributeCode) === filterKey)?.options
                      ?.find((opt) => opt.value === filterValue)?.label ?? filterValue}
                  </Label>
                </For>
              </If>
            </For>
            <LoadingOverlay active={loading} />
          </CurrentFiltersBody>
        </CurrentFilters>
      </If>
      <For each='filterGroup' of={filteredAggregations}>
        <Choose>
          <When condition={filterGroup.type === AggregationTypeEnum.CATEGORY}>
            <FilterGroup key={filterGroup.attributeCode}>
              <DeviceContainer mobile>
                <FilterHeading
                  onClick={() => _handleToggleFilterSection(filterGroup.attributeCode)}>
                  <Heading
                    variant='h8'
                    color={theme.colors.green.greenVogue}>
                    {filterGroup.label}
                  </Heading>
                  <Icon className="icon" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CHEVRON_DOWN} />
                </FilterHeading>
              </DeviceContainer>
              <DeviceContainer desktop tablet>
                <Heading
                  variant='h8'
                  className='filter-heading'
                  color={theme.colors.green.greenVogue}>
                  {filterGroup.label}
                </Heading>
              </DeviceContainer>
              <If condition={!state.tabs[filterGroup.attributeCode]}>
                <Filters>
                  <For each='option' of={filterGroup.options}>
                    <Filter
                      key={option.value}
                      href='#'
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => _handleFilter(filterGroup.attributeCode, option.value, e)}
                      active={filters?.[camelCase(filterGroup.attributeCode)]?.in?.includes(option.value)}>
                      <If condition={state.loadingFilter?.[filterGroup.attributeCode] === option.value}>
                        <FilterLoaderContainer>
                          <SmallLoader />
                        </FilterLoaderContainer>
                      </If>
                      <Paragraph variant='p2' className='text-item'>
                        {option.label}
                      </Paragraph>
                    </Filter>
                  </For>
                  <LoadingOverlay active={loading} />
                </Filters>
              </If>
            </FilterGroup>
          </When>
          <When condition={filterGroup.type === AggregationTypeEnum.SINGLE_SELECT}>
            <FilterGroup key={filterGroup.attributeCode}>
              <DeviceContainer mobile>
                <FilterHeading
                  onClick={() => _handleToggleFilterSection(filterGroup.attributeCode)}>
                  <Heading
                    variant='h8'
                    color={theme.colors.green.greenVogue}>
                    {filterGroup.label}
                  </Heading>
                  <Icon className="icon" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CHEVRON_DOWN} />
                </FilterHeading>
              </DeviceContainer>
              <DeviceContainer desktop tablet>
                <Heading
                  variant='h8'
                  className='filter-heading'
                  color={theme.colors.green.greenVogue}>
                  {filterGroup.label}
                </Heading>
              </DeviceContainer>
              <If condition={filterGroup.attributeCode === 'category_uid'}>
                <Form onFieldsChange={_handleFieldsChange} form={form} className='search' >
                  <TextInput showLabel={false} name='categorySearch' placeholder='Search by Category' />
                </Form>
              </If>
              <If condition={filterGroup.attributeCode === 'manufacturer'}>
                <Form onFieldsChange={_handleFieldsChange} form={form} className='search' >
                  <TextInput showLabel={false} name='brandSearch' placeholder='Search by Brand' />
                </Form>
              </If>
              <If condition={!state.tabs[filterGroup.attributeCode]}>
                <Filters>
                  <For each='option' of={filterGroup.options}>
                    <Choose>
                      <When condition={filterGroup.attributeCode === 'category_uid'}>
                        <If condition={option.label.toLowerCase().includes(state.categorySearch.toLowerCase()) || !state.categorySearch}>
                          <Filter
                            key={option.value}
                            href='#'
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => _handleFilter(filterGroup.attributeCode, option.value, e)}
                            active={filters?.[camelCase(filterGroup.attributeCode)]?.in?.includes(option.value)}>
                            <If condition={state.loadingFilter?.[filterGroup.attributeCode] === option.value}>
                              <FilterLoaderContainer>
                                <SmallLoader />
                              </FilterLoaderContainer>
                            </If>
                            <Paragraph variant='p2' className='text-item'>
                              {option.label}
                            </Paragraph>
                          </Filter>
                        </If>
                      </When>
                      <When condition={filterGroup.attributeCode === 'manufacturer'}>
                        <If condition={option.label.toLowerCase().includes(state.brandSearch.toLowerCase()) || !state.brandSearch}>
                          <Filter
                            key={option.value}
                            href='#'
                            onClick={(e: React.MouseEvent<HTMLAnchorElement>) => _handleFilter(filterGroup.attributeCode, option.value, e)}
                            active={filters?.[camelCase(filterGroup.attributeCode)]?.in?.includes(option.value)}>
                            <If condition={state.loadingFilter?.[filterGroup.attributeCode] === option.value}>
                              <FilterLoaderContainer>
                                <SmallLoader />
                              </FilterLoaderContainer>
                            </If>
                            <Paragraph variant='p2' className='text-item'>
                              {option.label}
                            </Paragraph>
                          </Filter>
                        </If>
                      </When>
                      <Otherwise>
                        <Filter
                          key={option.value}
                          href='#'
                          onClick={(e: React.MouseEvent<HTMLAnchorElement>) => _handleFilter(filterGroup.attributeCode, option.value, e)}
                          active={filters?.[camelCase(filterGroup.attributeCode)]?.in?.includes(option.value)}>
                          <If condition={state.loadingFilter?.[filterGroup.attributeCode] === option.value}>
                            <FilterLoaderContainer>
                              <SmallLoader />
                            </FilterLoaderContainer>
                          </If>
                          <Paragraph variant='p2' className='text-item'>
                            {option.label}
                          </Paragraph>
                        </Filter>
                      </Otherwise>
                    </Choose>
                  </For>
                  <LoadingOverlay active={loading} />
                </Filters>
              </If>
            </FilterGroup>
          </When>
          <When condition={filterGroup.type === AggregationTypeEnum.MULTI_SELECT}>
            <FilterGroup key={filterGroup.attributeCode}>
              <DeviceContainer mobile>
                <FilterHeading onClick={() => _handleToggleFilterSection(filterGroup.attributeCode)} >
                  <Heading
                    variant='h8'
                    color={theme.colors.green.greenVogue}>
                    {filterGroup.label}
                  </Heading>
                  <Icon className="icon" color={theme.colors.green.bottleGreen} icon={LocalIconEnums.CHEVRON_DOWN} />
                </FilterHeading>
              </DeviceContainer>
              <DeviceContainer desktop tablet>
                <Heading
                  variant='h8'
                  className='filter-heading'
                  color={theme.colors.green.greenVogue}>
                  {filterGroup.label}
                </Heading>
              </DeviceContainer>
              <If condition={!state.tabs[filterGroup.attributeCode]}>
                <Filters>
                  <For each='option' of={filterGroup.options}>
                    <Filter
                      key={option.value}
                      href='#'
                      onClick={(e: React.MouseEvent<HTMLAnchorElement>) => _handleFilter(filterGroup.attributeCode, option.value, e)}
                      active={filters?.[filterGroup.attributeCode]?.in?.includes(option.value)}>
                      <Choose>
                        <When condition={state.loadingFilter?.[filterGroup.attributeCode] === option.value}>
                          <FilterLoaderContainer>
                            <SmallLoader />
                          </FilterLoaderContainer>
                        </When>
                        <Otherwise>
                          <CheckBox className='check-box' checked={filters?.[camelCase(filterGroup.attributeCode)]?.in?.includes(option.value)}>
                            <If condition={filters?.[camelCase(filterGroup.attributeCode)]?.in?.includes(option.value)}>
                              <Icon className="icon" color={theme.colors.white.pureWhite} icon={LocalIconEnums.CHECK} />
                            </If>
                          </CheckBox>
                        </Otherwise>
                      </Choose>
                      <Paragraph variant='p2' className='text-item'>
                        {option.label}
                      </Paragraph>
                    </Filter>
                  </For>
                  <LoadingOverlay active={loading} />
                </Filters>
              </If>
            </FilterGroup>
          </When>
        </Choose>
      </For>
    </Container>
  )

  return (
    <>
      <Choose>
        <When condition={aggregations.length === 0}>
          <DeviceContainer desktop tablet>
            <Container>
              <Skeleton
                height={{ mobile: '157px', tablet: '350px', desktop: '600px' }}
                direction='column'
                justify='flex-start'>
                <SkeletonNode
                  height={{ mobile: '20px', tablet: '50px', desktop: '55px' }} />
                <SkeletonNode
                  color='gallery'
                  height={{ mobile: '18px', tablet: '45px', desktop: '45px' }}
                />
                <Skeleton
                  padding={{ mobile: '4px', tablet: '10px', desktop: '16px' }}
                  height={{ mobile: '20px', tablet: '500px', desktop: '500px' }}
                  gap='16px'
                  justify='flex-start'
                  align='flex-start'
                  direction='column'
                  loop={15}>
                  <SkeletonNode
                    key={skeletonIndex}
                    color='gallery'
                    variWidth={{ mobile: '100%', tablet: '40%-80%', desktop: '40%-80%' }}
                    height={{ mobile: '18px', tablet: '20px', desktop: '20px' }}
                  />
                </Skeleton>
              </Skeleton>
            </Container>
          </DeviceContainer>
        </When>
        <Otherwise>
          <DeviceContainer desktop tablet>
            {$filters}
          </DeviceContainer>
          <DeviceContainer mobile>
            <MenuContainer
              className='scroll-lock-ignore'
              style={{
                ...sidebarContainerProps,
                display: sidebarContainerProps.dspl.to((displ) =>
                  displ === 0 ? 'none' : 'initial',
                ),
              }} onClick={_handleBackgroundClose}>
              <MobileFilterContainer style={containerProps}>
                {$filters}
              </MobileFilterContainer>
            </MenuContainer>
          </DeviceContainer>
        </Otherwise>
      </Choose>

    </>
  )

}
