import React, { useEffect, useMemo, useRef, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { GlobalModalTypeEnum, ModalPlugin } from '@api/local/ModalPlugin'
import { Paragraph, Title, Card, FavouriteButton, Button, LocalIconEnums, Link } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { ConfigurableProductDetailsFragment, ConfigurableProductOptionsFragment, ConfigurableProductOptionsValuesFragment, ConfigurableProductVariantDetailsFragment, ProductDetailsFragment, useAddConfigurableProductToCartMutation, useChangeCartItemQuantityMutation, useCustomerQuery, useProductAlertSubscribeMutation, useRemoveItemFromCartMutation } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { SiteHelper } from '@lib/SiteHelper'
import { SelectInput } from '@molecules/inputs'
import { Form, useForm } from '@molecules/inputs/Form'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, ProductStockStatus } from '@uctypes/api/globalTypes'
import { DeviceContainer } from '@utility/DeviceContainer'

import { ProductAlertTypeEnum } from './AddSimpleProductToCart'
import { AddToCartButton, AddToCartDisplayTypeEnum } from './AddToCartButton'

const QUANTITY_CHANGE_TIMEOUT = 2000

const Container = styled.div`
  width: 100%;
  ${ResponsivePXValue('box-shadow', '0px -2px 6px rgba(0, 0, 0, 0.1)')}
  .card {
    margin: 0;
    ${ResponsivePXValue('padding', '10px 12px 12px 12px')}
  }
  .add-to-cart-button {
    width: 100%;
  }
`

const UpperContainer = styled.div`
  .heading {
    ${ResponsivePXValue('margin', '0 5px 0 0')}
  }
  .notify {
    ${ResponsivePXValue('margin', '0 0 12px 0')}
  }
  .mobile-inner {
    margin: 0;
  }
`

const InnerContainer = styled.div`
  display: flex;
  flex-direction: 'row';
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('margin', '0 0 10px 0')}
  .discount {}
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CartFavContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  ${ResponsivePXValue('gap', '12px')}
`

const SelectOptionText = styled.div`
  background-color: ${(props): string => props.theme.colors.white.fantasy};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  ${ResponsivePXValue('margin-top', '12px')}
  ${ResponsivePXValue('padding', '8px')}
`

const OptionsContainer = styled.div``

const OptionContainer = styled.div`
  display:flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;

  ${ResponsivePXValue('margin-bottom', '8px')}
  
  .mobile-select-form {
    width: 100%;
    ${ResponsivePXValue('margin-bottom', '12px')}
  }

  .mobile-select-input {
    margin: 0;
  }

  .add-to-cart-select {
    border-color: ${(props): string => props.theme.colors.green.viridian};
    ${ResponsivePXValue('border-width', '2px')}
    width: 100%;
  }
`

const OptionValuesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  
  ${ResponsivePXValue('margin', '12px 0')}
  ${ResponsivePXValue('gap', '8px')}
`

export interface AddConfigurableProductToCartProps {
  product: ConfigurableProductDetailsFragment & ProductDetailsFragment
  loading: boolean
  selectedVariant: string | void
  onSelectedVariantChange: (selectedVariant: string | null) => void
}

interface AddConfigurableProductToCartState {
  newAmount: number | null
  selectedOptions: { [k: string]: number }
}

const DEFAULT_STATE: AddConfigurableProductToCartState = {
  newAmount: null,
  selectedOptions: {},
}

const getVarintsForOptions = (product: ConfigurableProductDetailsFragment, selectedOptions: { [k: string]: number }): ConfigurableProductVariantDetailsFragment[] => {
  const variants = product.variants.filter((variant) => {
    const allOptionKeys = Object.keys(selectedOptions)
    for (let a = 0; a < allOptionKeys.length; a++) {
      let found = false
      for (let b = 0; b < variant.attributes.length; b++) {
        if (variant.attributes[b].valueIndex === selectedOptions[allOptionKeys[b]] && variant.attributes[b].code === allOptionKeys[b]) {
          found = true
          break
        }
      }
      if (!found) {
        return false
      }
    }
    return true
  })
  return variants.map((variant) => variant.product)
}

const getStockAndPriceForOption = (product: ConfigurableProductDetailsFragment, option: string, value: number, selectedOptions: { [k: string]: number }): { stockStatus: ProductStockStatus, price: string } => {
  const allOptions = { ...selectedOptions, [option]: value }
  const variants = getVarintsForOptions(product, allOptions)

  let stockStatus: ProductStockStatus
  let minPriceIndex = 0
  if (variants.length > 1) {
    for (let p = 0; p < variants.length; p++) {
      if (variants[p].priceRange.minimumPrice.finalPrice.value > minPriceIndex) {
        minPriceIndex = p
      }
    }
  }

  const price = SiteHelper.formatCurrency(variants[minPriceIndex].priceRange.minimumPrice.finalPrice)
  const inStockVariant = variants.find((variant) => variant.stockStatus === ProductStockStatus.IN_STOCK)
  if (inStockVariant) {
    stockStatus = ProductStockStatus.IN_STOCK
  } else {
    stockStatus = ProductStockStatus.OUT_OF_STOCK
  }

  return { stockStatus, price: variants.length > 1 ? `From ${price}` : price }
}

const getStockAndPriceForSelection = (product: ConfigurableProductDetailsFragment, selectedOptions: { [k: string]: number }): { [k: string]: { [k: number]: { stockStatus: ProductStockStatus, price: string } } } => {
  const responseObject: { [k: string]: { [k: number]: { stockStatus: ProductStockStatus, price: string } } } = {}
  product?.configurableOptions.forEach((configurableOption) => {
    configurableOption.values.forEach((value) => {
      const { stockStatus, price } = getStockAndPriceForOption(product, configurableOption.attributeCode, value.valueIndex, selectedOptions)
      if (!responseObject[configurableOption.attributeCode]) {
        responseObject[configurableOption.attributeCode] = {}
      }
      responseObject[configurableOption.attributeCode][value.valueIndex] = { stockStatus, price }
    })
  })
  return responseObject
}

const getDisplayPriceForSelection = (product: ConfigurableProductDetailsFragment, selectedOptions: { [k: string]: number }): { normal: string, current: string } => {
  const variants = getVarintsForOptions(product, selectedOptions)
  if (variants.length > 1) {
    let minPriceIndex = 0
    if (variants.length > 1) {
      for (let p = 0; p < variants.length; p++) {
        if (variants[p].priceRange.minimumPrice.finalPrice.value > minPriceIndex) {
          minPriceIndex = p
        }
      }
    }
    const normal = 'From ' + SiteHelper.formatCurrency(variants[minPriceIndex].priceRange.minimumPrice.regularPrice)
    const current = 'From ' + SiteHelper.formatCurrency(variants[minPriceIndex].priceRange.minimumPrice.finalPrice)
    return {
      normal,
      current,
    }
  } else {
    const normal = SiteHelper.formatCurrency(variants[0].priceRange.minimumPrice.regularPrice)
    const current = SiteHelper.formatCurrency(variants[0].priceRange.minimumPrice.finalPrice)
    return {
      normal,
      current,
    }
  }
}

export function AddConfigurableProductToCart({ product, loading, selectedVariant, onSelectedVariantChange }: AddConfigurableProductToCartProps): JSX.Element {

  const theme = useTheme()
  const navigate = useNavigate()
  const { data: customerData } = useCustomerQuery()
  const [state, setState] = useState<AddConfigurableProductToCartState>({ ...DEFAULT_STATE })
  const { cartId } = useCartId()
  const { addToast } = useSimpleToasts()
  const updateTimerRef = useRef(null)
  const form = useForm()
  const [addProductToCart, { loading: addLoading }] = useAddConfigurableProductToCartMutation()
  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation()
  const [changeQuantity, { loading: quantityLoading }] = useChangeCartItemQuantityMutation()
  const [productAlertSubscribeMutation, { loading: ProductAlertSubscriptionLoading }] = useProductAlertSubscribeMutation()

  const varinatInfo = useMemo(() => getStockAndPriceForSelection(product, state.selectedOptions), [state.selectedOptions])
  const price = useMemo(() => getDisplayPriceForSelection(product, state.selectedOptions), [state.selectedOptions])
  const actualQuantityInCart = selectedVariant
    ? product.variants.find((variant) => variant.product.sku === selectedVariant).product.quantityInCart
    : 0

  const addProduct = async (): Promise<void> => {
    if (!selectedVariant) {
      return
    }
    try {
      await addProductToCart({
        variables: {
          input: {
            cartId,
            cartItems: [{
              parentSku: product.sku,
              variantSku: selectedVariant,
              data: {
                parentSku: product.sku,
                sku: selectedVariant,
                quantity: 1,
              },
            }],
          },
        },
      })
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const removeProduct = async (): Promise<void> => {
    try {
      await removeItemFromCart({
        variables: {
          cartId,
          cartItemUid: product.cartItemUid,
        },
      })
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    } finally {
      setState((prevState) => update(prevState, {
        newAmount: { $set: null },
      }))
    }
  }

  const changeProduct = async (): Promise<void> => {
    const cartItemUid = product.variants.find((variant) => variant.product.sku === selectedVariant).product.cartItemUid
    updateTimerRef.current = setTimeout(async () => {
      try {
        await changeQuantity({
          variables: {
            cartId,
            cartItemUid,
            quantity: state.newAmount,
          },
        })
      } catch (e) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      } finally {
        setState((prevState) => update(prevState, {
          newAmount: { $set: null },
        }))
      }
    }, QUANTITY_CHANGE_TIMEOUT)
  }

  const updateProduct = async (): Promise<void> => {
    if (updateTimerRef.current) {
      clearTimeout(updateTimerRef.current)
    }
    if (!actualQuantityInCart) {
      await addProduct()
    } else if (actualQuantityInCart && state.newAmount === 0) {
      removeProduct()
    } else {
      changeProduct()
    }
  }

  const _handleAddProduct = async (): Promise<void> => {
    await addProduct()
  }

  const _handleIncreaseProduct = async (): Promise<void> => {
    const newAmount = state.newAmount === null ? actualQuantityInCart + 1 : state.newAmount + 1
    setState((prevState) => update(prevState, {
      newAmount: { $set: newAmount },
    }))
  }

  const _handleDecreaseProduct = async (): Promise<void> => {
    let newAmount = state.newAmount === null ? actualQuantityInCart - 1 : state.newAmount - 1
    if (newAmount < 0) {
      newAmount = 0
    }
    setState((prevState) => update(prevState, {
      newAmount: { $set: newAmount },
    }))
  }

  const _handleSelectOption = (code: string, value: number): void => {
    if (state.selectedOptions[code] === value) {
      setState((prevState) => update(prevState, {
        selectedOptions: {
          $apply: (value) => {
            delete value[code]
            return value
          },
        },
      }))
    } else {
      setState((prevState) => update(prevState, {
        selectedOptions: {
          [code]: {
            $set: value,
          },
        },
      }))
    }
  }

  const _handleSelectOptions = (options: { [k: string]: number }): void => {
    setState((prevState) => update(prevState, {
      selectedOptions: {
        $set: options,
      },
    }))
  }

  const _handleProductAlertSubscription = async (): Promise<void> => {
    if (customerData.currentCustomer.customerType === CustomerTypeEnum.GUEST) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    } else {
      try {
        await productAlertSubscribeMutation({
          variables: {
            productId: product.id as unknown as string,
            type: ProductAlertTypeEnum.PRODUCT_ALERT_IN_STOCK as unknown as string,
          },
        })
      } catch (e) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      } finally {
        addToast({
          message: 'Alert subscription has been saved',
          appearance: 'success',
        })
      }

    }
  }

  const displayType = product?.stockStatus === ProductStockStatus.OUT_OF_STOCK
    ? AddToCartDisplayTypeEnum.OUT_OF_STOCK
    : actualQuantityInCart
      ? AddToCartDisplayTypeEnum.QUANTITY
      : !selectedVariant
        ? AddToCartDisplayTypeEnum.SELECT_VARIANT
        : AddToCartDisplayTypeEnum.ADD

  useEffect(() => {
    const variants = getVarintsForOptions(product, state.selectedOptions)
    if (variants.length === 1) {
      onSelectedVariantChange(variants[0].sku)
    } else {
      onSelectedVariantChange(null)
    }
  }, [state.selectedOptions])

  useEffect(() => {
    if (state.newAmount !== null) {
      updateProduct()
    }
  }, [state.newAmount])

  const _handleGoToCart = (e: React.MouseEvent<HTMLAnchorElement>): void => {
    e.preventDefault()
    navigate('/cart')
  }

  const optionUnSelectedColors = {
    color: theme.colors.green.greenVogue,
    backgroundColor: theme.colors.grey.gallery,
    borderColor: theme.colors.misc.transparent,
    hoverColor: theme.colors.green.greenVogue,
    hoverBackgroundColor: theme.colors.misc.transparent,
    hoverBorderColor: theme.colors.green.greenVogue,
    disabledColor: theme.colors.grey.gallery,
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.grey.gallery,
  }

  const optionSelectedColors = {
    color: theme.colors.green.greenVogue,
    backgroundColor: theme.colors.green.magicMint,
    borderColor: theme.colors.green.magicMint,
    hoverColor: theme.colors.green.greenVogue,
    hoverBackgroundColor: theme.colors.green.magicMint,
    hoverBorderColor: theme.colors.green.greenVogue,
    disabledColor: theme.colors.grey.gallery,
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: theme.colors.grey.gallery,
  }

  const goToCartBtnColors = {
    color: theme.colors.green.bottleGreen,
    backgroundColor: theme.colors.misc.transparent,
    borderColor: theme.colors.grey.gallery,
    hoverColor: theme.colors.white.pureWhite,
    hoverBackgroundColor: theme.colors.green.bottleGreen,
    hoverBorderColor: theme.colors.green.bottleGreen,
    disabledColor: SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.45),
    disabledBackgrondColor: theme.colors.misc.transparent,
    disabledBorderColor: SiteHelper.getOpaqueColor(theme.colors.green.bottleGreen, 0.45),
  }

  const actualLoading = loading || addLoading || quantityLoading || removeLoading

  // let option!: SelectableOptionValues //?

  let configurableOption!: ConfigurableProductOptionsFragment
  let configurableValue!: ConfigurableProductOptionsValuesFragment

  return (
    <Container>
      <Card className='card' >
        <DeviceContainer desktop>
          <UpperContainer>
            <>
              <InnerContainer>
                <PriceContainer>
                  <Title className='heading' variant='t2'>{price.current}</Title>
                  <If condition={price.current !== price.normal}>
                    <Paragraph
                      color={theme.colors.grey.gunSmoke}
                      variant='p2'
                      className='discount'
                      decoration='line-through'>
                      {price.normal}
                    </Paragraph>
                  </If>
                </PriceContainer>
                <CartFavContainer>
                  <FavouriteButton className='favourite' product={product} />
                  <If condition={actualQuantityInCart > 0}>
                    <DeviceContainer mobile>
                      <Button
                        title='GO TO'
                        rightIcon={LocalIconEnums.CART_FILLED}
                        shape='round' size='large'
                        colors={goToCartBtnColors}
                        href='/cart'
                        onClick={_handleGoToCart} />
                    </DeviceContainer>
                  </If>
                </CartFavContainer>
              </InnerContainer>
              <For each='configurableOption' of={product.configurableOptions}>
                <If condition={!!configurableOption.values.length}>
                  {/* TODO: container here */}
                  <OptionsContainer key={configurableOption.attributeCode}>
                    <SelectOptionText>
                      <Paragraph
                        color={theme.colors.green.bottleGreen}
                        variant='p1'>
                        Select {configurableOption.label}
                      </Paragraph>
                    </SelectOptionText>
                    <OptionContainer>
                      <OptionValuesContainer>
                        <For each='configurableValue' of={configurableOption.values}>
                          <Choose>
                            <When condition={varinatInfo[configurableOption.attributeCode][configurableValue.valueIndex].stockStatus === ProductStockStatus.IN_STOCK}>
                              <Button
                                key={configurableOption.attributeCode + configurableValue.valueIndex}
                                title={configurableValue.label + ' - ' + varinatInfo[configurableOption.attributeCode][configurableValue.valueIndex].price}
                                size='medium'
                                shape='round'
                                colors={state.selectedOptions[configurableOption.attributeCode] === configurableValue.valueIndex ? optionSelectedColors : optionUnSelectedColors}
                                onClick={() => _handleSelectOption(configurableOption.attributeCode, configurableValue.valueIndex)} />
                            </When>
                            <Otherwise>
                              <Button
                                key={configurableOption.attributeCode + configurableValue.valueIndex}
                                title={configurableValue.label + ' - No Stock'}
                                size='medium'
                                disabled={true}
                                shape='round'
                                colors={optionUnSelectedColors} />
                            </Otherwise>
                          </Choose>
                        </For>
                      </OptionValuesContainer>
                    </OptionContainer>
                  </OptionsContainer>
                </If>
              </For>
              <If condition={displayType === AddToCartDisplayTypeEnum.OUT_OF_STOCK}>
                <Paragraph>
                  <Link className='notify' href='#' display='block' onClick={_handleProductAlertSubscription}>Notify me when back in stock</Link>
                </Paragraph>
              </If>
            </>
          </UpperContainer>
          <AddToCartButton
            displayType={displayType}
            currentQuantity={state.newAmount ? state.newAmount : actualQuantityInCart}
            loading={actualLoading}
            disabled={!selectedVariant}
            onAdd={_handleAddProduct}
            onIncrease={_handleIncreaseProduct}
            onDecrease={_handleDecreaseProduct} />
        </DeviceContainer>
        <DeviceContainer mobile tablet>
          <UpperContainer>
            <>
              <OptionsContainer>
                <Form form={form} onValuesChange={_handleSelectOptions} className='mobile-select-form'>
                  <For each='configurableOption' of={product.configurableOptions}>
                    <If condition={!!configurableOption.values.length}>
                      <OptionContainer key={configurableOption.attributeCode}>
                        <SelectInput
                          name={configurableOption.attributeCode}
                          label=''
                          direction='top'
                          className='add-to-cart-select'
                          placeholder={`Select ${configurableOption.label}`}
                          options={configurableOption?.values?.map((configurableValue) => {
                            return varinatInfo[configurableOption.attributeCode][configurableValue.valueIndex].stockStatus === ProductStockStatus.IN_STOCK
                              ? {
                                title: `${configurableValue.label} - ${varinatInfo[configurableOption.attributeCode][configurableValue.valueIndex].price}`,
                                value: configurableValue.valueIndex,
                              }
                              : {
                                title: `${configurableValue.label} - No Stock`,
                                value: configurableValue.valueIndex,
                                disabled: true,
                              }
                          })}
                          wrapperClassName='mobile-select-input' />

                      </OptionContainer>
                    </If>
                  </For>
                </Form>
              </OptionsContainer>
              <InnerContainer className='mobile-inner'>
                <PriceContainer>
                  <Title className='heading' variant='t2'>{price.current}</Title>
                  <If condition={price.current !== price.normal}>
                    <Paragraph
                      color={theme.colors.grey.gunSmoke}
                      variant='p2'
                      className='discount'
                      decoration='line-through'>
                      {price.normal}
                    </Paragraph>
                  </If>
                </PriceContainer>
                <CartFavContainer>
                  <AddToCartButton
                    displayType={displayType}
                    currentQuantity={state.newAmount ? state.newAmount : actualQuantityInCart}
                    loading={actualLoading}
                    disabled={!selectedVariant}
                    onAdd={_handleAddProduct}
                    onIncrease={_handleIncreaseProduct}
                    onDecrease={_handleDecreaseProduct} />
                </CartFavContainer>
              </InnerContainer>
              <If condition={displayType === AddToCartDisplayTypeEnum.OUT_OF_STOCK}>
                <Paragraph>
                  <Link className='notify' href='#' display='block' onClick={_handleProductAlertSubscription}>Notify me when back in stock</Link>
                </Paragraph>
              </If>
            </>
          </UpperContainer>
        </DeviceContainer>
      </Card>
    </Container>
  )

}
