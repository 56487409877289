import React, { useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { GlobalModalTypeEnum, ModalPlugin } from '@api/local/ModalPlugin'
import { Button, ButtonColors } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Label } from '@atoms/notifications'
import { Paragraph, Tag, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CartFragment, useAddCouponCodeToCartMutation, useRemoveCouponFromCartMutation, CustomerFragment, useGetStoreConfigQuery, useUseRewardPointsMutation, useCustomerRewardsQuery, useCartQuery, useCustomerQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { Form, TextInput, useForm } from '@molecules/inputs'
import { OutOfStockModal } from '@molecules/modals/OutOfStockModal'
import { useSimpleToasts } from '@simple/toasts'
import { CustomerTypeEnum, ProductStockStatus } from '@uctypes/api/globalTypes'
import { useCartId } from '@hooks/UseCartId'

const Container = styled.div`
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
`

const TopBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '2px')}
  ${ResponsivePXValue('height', '30px')}
`

const PromoButton = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${(props): string => props.theme.colors.green.magicMint};
`

const PointsButton = styled.div`
  position: relative;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${(props): string => props.theme.colors.green.snowFlurry};
`
const ContentContainer = styled.div`
  ${ResponsivePXValue('padding', '10px')}
  .checkout-button {
    width: 100%;
  }
  .points-text {
    margin: 0;
    width: 100%;
    ${ResponsivePXValue('margin', '0 0 14px')}
  }
  .points-disclaimer {
    font-style: italic;
    ${ResponsivePXValue('margin-top', '16px')}
  }
`

const PriceContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('padding', '10px 0')}
  .price-text {
    margin: 0;
  }
`

const CloseContainer = styled.div`
  position: absolute;
  ${ResponsivePXValue('top', '7px')}
  ${ResponsivePXValue('right', '7px')}
  ${ResponsivePXValue('width', '16px')}
  ${ResponsivePXValue('height', '16px')}
`

const PromoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-wrap: nowrap;
  ${ResponsivePXValue('gap', '10px')}

  .promo-item {
    flex-grow: 1;
    margin: 0;
  }

  .input {
    ${ResponsivePXValue('height', { mobile: '30px', tablet: '30px', desktop: '30px' })}
    ${ResponsivePXValue('margin-top', { mobile: '5px', tablet: '5px', desktop: '5px' })}
    background-color: ${(props): string => props.theme.colors.grey.pampas};
  }
`

const PromoLabels = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  ${ResponsivePXValue('gap', '5px')}
  ${ResponsivePXValue('margin-top', '10px')}
`

const Strong = styled.span`
  font-weight: 700;
`

enum DisplayTypeEnum {
  CHECKOUT = 'CHECKOUT',
  PROMOS = 'PROMOS',
  POINTS = 'POINTS',
}

export interface MobileCartCheckoutProps {
  cart: CartFragment
  customer: CustomerFragment
}

interface MobileCartCheckoutState {
  displayType: DisplayTypeEnum
  stock: boolean
}

const DEFAULT_STATE: MobileCartCheckoutState = {
  displayType: DisplayTypeEnum.CHECKOUT,
  stock: false,
}

export function MobileCartCheckout({ cart, customer }: MobileCartCheckoutProps): JSX.Element {

  const [state, setState] = useState<MobileCartCheckoutState>({ ...DEFAULT_STATE })
  const { data: customerData, loading: customerLoading } = useCustomerQuery()
  const [addCouponCodeToCart, { loading: addCouponLoading }] = useAddCouponCodeToCartMutation()
  const [removeCouponFromCart, { loading: removeCouponLoading }] = useRemoveCouponFromCartMutation()
  const [useRewardPoints, { loading: useRewardPointsLoading }] = useUseRewardPointsMutation()
  const { data: storeData } = useGetStoreConfigQuery()
  const { data: rewards, loading: rewardsLoading } = useCustomerRewardsQuery()
  const couponForm = useForm()
  const pointsForm = useForm()
  const theme = useTheme()
  const navigate = useNavigate()
  const { addToast } = useSimpleToasts()
  // TODO: filter
  const outOfStock = cart?.items?.filter((item) => item.product.stockStatus === ProductStockStatus.OUT_OF_STOCK) || []
  const { cartId } = useCartId()
  const { data: cartData, loading: cartLoading } = useCartQuery({ variables: { cartId }, skip: !cartId })


  const _handleCheckout = () => {
    if (outOfStock.length) {
      setState((prevState) => update(prevState, {
        stock: {
          $set: true,
        },
      }))
      return
    }
    if (customer.customerType === CustomerTypeEnum.GUEST) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    } else if (!customer.mobileVerified && storeData.storeConfig.otpConfirmationRequired) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.VALIDATE_MOBILE)
    } else {
      navigate('/checkout')
    }
  }

  const _useRewardPoints = async ({ amount }: {amount: string}): Promise<void> => {
    try {
      const results = await useRewardPoints({
        variables: {
          input: {
            cartId: cart?.id,
            points: parseFloat(amount),
          },
        },
      })
      addToast({
        message: results.data?.useRewardPoints?.response,
        appearance: 'success',
      })
      pointsForm.resetFields()
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _handleApplyPromo = async ({ code }: { code: string }): Promise<void> => {
    try {
      await addCouponCodeToCart({
        variables: {
          input: {
            cartId: cart.id,
            couponCode: code,
          },
        },
      })
      couponForm.resetFields()
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _handleRemovePromo = async (): Promise<void> => {
    try {
      await removeCouponFromCart({
        variables: {
          input: {
            cartId: cart.id,
          },
        },
      })
      couponForm.resetFields()
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const _handleOutOfStockDone = (): void => {
    setState((prevState) => update(prevState, {
      stock: {
        $set: false,
      },
    }))
  }

  const _handleLogin = (displayType: DisplayTypeEnum): void => {
    if (customerData.currentCustomer.customerType === CustomerTypeEnum.GUEST) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    }

    setState((prevState) => update(prevState, { displayType: { $set: displayType } }))
  }

  const checkoutButtonColors: ButtonColors = {
    color: theme.colors.white.pureWhite,
    backgroundColor: theme.colors.green.bottleGreen,
    borderColor: theme.colors.green.bottleGreen,
    hoverColor: theme.colors.green.bottleGreen,
    hoverBackgroundColor: theme.colors.yellow.cornSilk,
    hoverBorderColor: theme.colors.green.bottleGreen,
  }

  const total = SiteHelper.formatCurrency(cart.prices.grandTotal)
  let promo: { code: string }

  return (
    <Container>
      <OutOfStockModal cartId={cart.id} cartItems={outOfStock} open={state.stock} onClose={_handleOutOfStockDone} />
      <Choose>
        <When condition={state.displayType === DisplayTypeEnum.CHECKOUT && cartData?.cart?.items?.length > 0}>
          <TopBar>
            <PromoButton onClick={() => _handleLogin(DisplayTypeEnum.PROMOS)}>
              <Tag variant='t5'>Promo Codes & Gift Vouchers</Tag>
            </PromoButton>
            <If condition={rewards?.rewards?.balance > 0 && !rewardsLoading}>
              <PointsButton onClick={() => _handleLogin(DisplayTypeEnum.POINTS)}>
                <Tag variant='t5'>Spend Faithful points</Tag>
              </PointsButton>
            </If>
          </TopBar>
          <ContentContainer>
            <If condition={!!cart?.appliedCoupons?.length}>
              <PromoLabels>
                <For each='promo' of={cart?.appliedCoupons || []}>
                  <Label
                    key={promo.code}
                    onClose={_handleRemovePromo}
                    color={theme.colors.green.bottleGreen}
                    backgroundColor={theme.colors.green.magicMint}>
                    {promo.code}
                  </Label>
                </For>
              </PromoLabels>
            </If>
            <PriceContainer>
              <Paragraph variant='p1' className='price-text' bold>Amount to pay</Paragraph>
              <Title variant='t9' className='price-text'>{total}</Title>
            </PriceContainer>
            <Button colors={checkoutButtonColors} onClick={_handleCheckout} title='CHECKOUT NOW' className='checkout-button' />
          </ContentContainer>
        </When>
        <When condition={state.displayType === DisplayTypeEnum.PROMOS}>
          <TopBar onClick={() => setState((prevState) => update(prevState, { displayType: { $set: DisplayTypeEnum.CHECKOUT } }))}>
            <PromoButton>
              <Tag variant='t5'>Promo Codes & Gift Vouchers</Tag>
              <CloseContainer>
                <Icon icon={LocalIconEnums.CLOSE} color={theme.colors.green.bottleGreen} />
              </CloseContainer>
            </PromoButton>
          </TopBar>
          <ContentContainer>
            <Form
              loading={addCouponLoading || removeCouponLoading}
              form={couponForm}
              onFinish={_handleApplyPromo}>
              <PromoContainer>
                <TextInput
                  name='code'
                  label='Promo Code'
                  className='input'
                  wrapperClassName='promo-item'
                  placeholder='Enter Promo Code' />
                <Button
                  loading={addCouponLoading || removeCouponLoading}
                  title='APPLY'
                  variant='ghost'
                  size='medium'
                  className='promo-button'
                  onClick={couponForm.submit} />
              </PromoContainer>
            </Form>
            <If condition={!!cart?.appliedCoupons?.length}>
              <PromoLabels>
                <For each='promo' of={cart?.appliedCoupons || []}>
                  <Label
                    key={promo.code}
                    onClose={_handleRemovePromo}
                    color={theme.colors.green.bottleGreen}
                    backgroundColor={theme.colors.green.magicMint}>
                    {promo.code}
                  </Label>
                </For>
              </PromoLabels>
            </If>
          </ContentContainer>
        </When>
        <When condition={state.displayType === DisplayTypeEnum.POINTS}>
          <TopBar onClick={() => setState((prevState) => update(prevState, { displayType: { $set: DisplayTypeEnum.CHECKOUT } }))}>
            <PointsButton>
              <Tag variant='t5'>Spend Faithful points</Tag>
              <CloseContainer>
                <Icon icon={LocalIconEnums.CLOSE} color={theme.colors.green.bottleGreen} />
              </CloseContainer>
            </PointsButton>
          </TopBar>
          <ContentContainer>
            <Tag variant='t2' className='points-text' align='center'>
              You have <Strong>{(rewards?.rewards?.balance) ? rewards?.rewards?.balance : '0'}</Strong> Faithful points
            </Tag>
            <Form
              form={pointsForm}
              loading={useRewardPointsLoading}
              onFinish={_useRewardPoints}>
              <PromoContainer>
                <TextInput
                  name='amount'
                  label='Faithful Points'
                  className='input'
                  wrapperClassName='promo-item'
                  placeholder='Enter Amount' />
                <Button
                  title='APPLY'
                  variant='ghost'
                  size='medium'
                  className='promo-button'
                  onClick={() => pointsForm.submit()} />
              </PromoContainer>
            </Form>
            <Tag variant='t3' className='points-disclaimer' align='center' color={theme.colors.green.greenVogue}>
              Faithful Points are only deducted when order is confirmed.
            </Tag>
          </ContentContainer>
        </When>
      </Choose>
    </Container>
  )

}
