import { ApolloClient, DocumentNode, FieldFunctionOptions, FieldPolicy, NormalizedCacheObject, StoreObject, TypePolicies, gql } from '@apollo/client'

import { GetProductReviewsQuery } from '@hooks/api/index'
import { ConfigPlugin } from '@lib/Config'
import { SiteHelper } from '@lib/SiteHelper'

const isBrowser = (): boolean => {
  return (typeof window !== 'undefined')
}

export class ProductPlugin implements ConfigPlugin {

  typePolicies = (): TypePolicies => ({
    ProductInterface: {
      keyFields: ['sku'],
      fields: {
        quantityInCart: {
          read(currentValue: number, options: FieldFunctionOptions): number {
            if (isBrowser()) {
              const sku = options.readField('sku') as string
              const quantitiesString = sessionStorage.getItem('CART_QUANTITIES')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const quantity = quantities?.[sku]?.quantity as number || 0
              return quantity
            }
            return 0
          },
        },
        cartItemUid: {
          read(currentValue: number, options: FieldFunctionOptions): number | null {
            if (isBrowser()) {
              const sku = options.readField('sku') as string
              const quantitiesString = sessionStorage.getItem('CART_QUANTITIES')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const cartItemUid = quantities?.[sku]?.cartItemUid || null
              return cartItemUid
            }
            return null
          },
        },
        isInWishlist: {
          read(currentValue: number, options: FieldFunctionOptions): boolean {
            if (isBrowser()) {
              const sku = options.readField('sku') as string
              const favouritesString = sessionStorage.getItem('WISHLIST')
              const favourites = favouritesString ? JSON.parse(favouritesString) : {}
              return !!favourites?.[sku]
            }
            return false
          },
        },
        wishlistItemId: {
          read(currentValue: number, options: FieldFunctionOptions): number | null {
            if (isBrowser()) {
              const sku = options.readField('sku') as string
              const quantitiesString = sessionStorage.getItem('WISHLIST')
              const quantities = quantitiesString ? JSON.parse(quantitiesString) : {}
              const wishlistItemId = quantities?.[sku]?.wishlistItemId as number || null
              return wishlistItemId
            }
            return null
          },
        },
        cacheBuster: {
          read(currentValue: string): string {
            return currentValue || SiteHelper.randomString(5)
          },
        },
      },
    },
    ProductReview: {
      keyFields: (object: StoreObject): string => {
        return object.reviewId + ''
      },
    },
  })

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    productReviews: {
      keyArgs: ['order', 'filters', 'sku'],
      read(existing: GetProductReviewsQuery['productReviews'], { args: { skip, limit } }): GetProductReviewsQuery['productReviews'] {
        if (existing) {
          return {
            ...existing,
            items: existing?.items?.slice(0, skip + limit) || [],
          }
        }
      },
      merge(existing: GetProductReviewsQuery['productReviews'], incoming: GetProductReviewsQuery['productReviews'], { args: { skip = 0, limit = 5 } }) {
        const merged = existing?.items ? existing?.items?.slice(0) : []
        for (let i = 0; i < incoming?.items?.length; ++i) {
          merged[skip + i] = incoming?.items[i]
        }
        return {
          ...incoming,
          items: merged,
        }
      },
    },
  })

  extensions = (): DocumentNode => gql`
    extend interface ProductInterface {
      quantityInCart: Int!
      cartItemUid: String
      cacheBuster: String
      isInWishlist: Boolean!
      wishlistItemId: Int
    }

    extend type VirtualProduct {
      quantityInCart: Int!
      cartItemUid: String
      cacheBuster: String
      isInWishlist: Boolean!
      wishlistItemId: Int
    }

    extend type SimpleProduct {
      quantityInCart: Int!
      cartItemUid: String
      cacheBuster: String
      isInWishlist: Boolean!
      wishlistItemId: Int
    }

    extend type DownloadableProduct {
      quantityInCart: Int!
      cartItemUid: String
      cacheBuster: String
      isInWishlist: Boolean!
      wishlistItemId: Int
    }

    extend type BundleProduct {
      quantityInCart: Int!
      cartItemUid: String
      cacheBuster: String
      isInWishlist: Boolean!
      wishlistItemId: Int
    }

    extend type GroupedProduct {
      quantityInCart: Int!
      cartItemUid: String
      cacheBuster: String
      isInWishlist: Boolean!
      wishlistItemId: Int
    }

    extend type ConfigurableProduct {
      quantityInCart: Int!
      cartItemUid: String
      cacheBuster: String
      isInWishlist: Boolean!
      wishlistItemId: Int
    }

    extend type AmGiftCardProduct {
      quantityInCart: Int!
      cartItemUid: String
      cacheBuster: String
      isInWishlist: Boolean!
      wishlistItemId: Int
    }
  `

}
