import { ApolloClient, DocumentNode, FieldPolicy, NormalizedCacheObject, gql, makeVar } from '@apollo/client'

import update from 'react-addons-update'
import { v4 } from 'uuid'

import { GetCategoryMenuChildrenDocument, GetCategoryMenuQueryDocument, NavigationFragment } from '@hooks/api/index'
import { ConfigPlugin } from '@lib/Config'
import { DropDownCartDisplayType, MobileMenuDisplayEnum } from '@uctypes/api/globalTypes'

import { AppPlugin } from './AppPlugin'

export const NAVIGATION_DEFAULT_STATE: NavigationFragment = {
  id: v4(),
  navigationOpen: false,
  searchOpen: false,
  path: '',
  isTest: false,
  preventScroll: false,
  showUserMenu: false,
  dropDownCartDisplayType: DropDownCartDisplayType.NONE,
  mobileMenuDisplayState: MobileMenuDisplayEnum.HIDE,
  __typename: 'Navigation',
}

const _data = makeVar<NavigationFragment>({ ...NAVIGATION_DEFAULT_STATE })

export class NavigationPlugin implements ConfigPlugin {

  static instance: NavigationPlugin

  static shared(): NavigationPlugin {
    if (!this.instance) {
      this.instance = new NavigationPlugin()
    }
    return this.instance
  }

  hasFetchedCategoryData = false

  setTest(isTest: boolean): void {
    _data(update(_data(), {
      isTest: {
        $set: isTest,
      },
    }))
  }

  setPath(client: ApolloClient<NormalizedCacheObject>, path: string): void {
    _data(update(_data(), {
      path: {
        $set: path,
      },
    }))
  }

  preventScroll(preventScroll: boolean): void {
    _data(update(_data(), {
      preventScroll: {
        $set: preventScroll,
      },
    }))
  }

  openNavigation() {
    _data(update(_data(), {
      navigationOpen: {
        $set: true,
      },
      searchOpen: {
        $set: false,
      },
    }))
  }

  closeNavigation() {
    _data(update(_data(), {
      navigationOpen: {
        $set: false,
      },
      searchOpen: {
        $set: false,
      },
    }))
  }

  openSearch() {
    _data(update(_data(), {
      navigationOpen: {
        $set: false,
      },
      searchOpen: {
        $set: true,
      },
    }))
  }

  closeSearch() {
    _data(update(_data(), {
      navigationOpen: {
        $set: false,
      },
      searchOpen: {
        $set: false,
      },
    }))
  }

  openUserMenu() {
    _data(update(_data(), {
      showUserMenu: {
        $set: true,
      },
      dropDownCartDisplayType: {
        $set: DropDownCartDisplayType.NONE,
      },
    }))
  }

  closeUserMenu() {
    _data(update(_data(), {
      showUserMenu: {
        $set: false,
      },
    }))
  }

  openCart(added: boolean) {
    _data(update(_data(), {
      dropDownCartDisplayType: {
        $set: added ? DropDownCartDisplayType.ADD_TO_CART : DropDownCartDisplayType.CART,
      },
      showUserMenu: {
        $set: false,
      },
    }))
  }

  closeCart() {
    _data(update(_data(), {
      dropDownCartDisplayType: {
        $set: DropDownCartDisplayType.NONE,
      },
    }))
  }

  toggleMobileMenuDisplay() {
    _data(update(_data(), {
      mobileMenuDisplayState: {
        $set: _data().mobileMenuDisplayState === MobileMenuDisplayEnum.SHOW ? MobileMenuDisplayEnum.HIDE : MobileMenuDisplayEnum.SHOW,
      },
    }))
  }

  toggleUserMenu() {
    if (_data().showUserMenu) {
      this.closeUserMenu()
    } else {
      this.openUserMenu()
    }
  }

  toggleNavigation() {
    if (_data().navigationOpen) {
      this.closeNavigation()
    } else {
      this.openNavigation()
    }
  }

  toggleSearch() {
    if (_data().searchOpen) {
      this.closeSearch()
    } else {
      this.openSearch()
    }
  }

  async onNetworkIdle(client: ApolloClient<NormalizedCacheObject>): Promise<void> {
    if (!this.hasFetchedCategoryData) {
      const query = client.readQuery({ query: GetCategoryMenuQueryDocument, variables: { rootCategoryId: AppPlugin.shared().getBaseCategoryId() } })
      for (let c = 0; c < query?.categories?.items?.length; c++) {
        if (query.categories.items[c].includeInMenu) {
          this.hasFetchedCategoryData = true
          client.query({
            query: GetCategoryMenuChildrenDocument,
            fetchPolicy: 'network-only',
            variables: {
              parentCategoryId: query.categories.items[c].uid,
            },
          })
        }
      }
    }
  }

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    navigation: {
      read(): NavigationFragment {
        const data = _data() as NavigationFragment
        return data
      },
    },
  })

  types = (): DocumentNode => gql`
    enum DropDownCartDisplayType {
      "Add to Cart"
      ADD_TO_CART
      "Cart"
      CART
      "None"
      NONE
    }
    enum MobileMenuDisplayEnum {
      "Show"
      SHOW
      "Hide"
      HIDE
    }
    type Navigation {
      id: ID!
      navigationOpen: Boolean!
      searchOpen: Boolean!
      path: String!
      isTest: Boolean!
      preventScroll: Boolean!
      showUserMenu: Boolean!
      dropDownCartDisplayType: DropDownCartDisplayType!
      mobileMenuDisplayState: MobileMenuDisplayEnum!
    }
  `

  extensions = (): DocumentNode => gql`
    extend type Query {
      navigation: Navigation!
    }
  `

  queries = (): DocumentNode => gql`
      fragment NavigationFragment on Navigation {
        id
        navigationOpen
        searchOpen
        path
        isTest
        preventScroll
        showUserMenu
        dropDownCartDisplayType
        mobileMenuDisplayState
      }
      
      query GetNavigation {
        navigation @client {
          ... NavigationFragment
        }
      }
    `

}
