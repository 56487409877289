import { ApolloClient, FieldFunctionOptions, gql, makeVar, NormalizedCacheObject, Reference, StoreObject, TypePolicies } from '@apollo/client'

import { DocumentNode } from 'graphql'

import { ShippingMethodFragment, PaymentMethodFragment, CartItemFragment, TempShippingActionsFragment, ShippingActionFragment, TempShippingActionFragment, TempActionItemsFragment, TempShortageItemFragment } from '@hooks/api/index'
import { Config, ConfigPlugin } from '@lib/Config'
import { Mutable } from '@uctypes/global'

interface CartSkuQuantity { quantity: number, cartItemUid: string, type: string }

interface CartQuantity { [k: string]: CartSkuQuantity }

const isBrowser = (): boolean => {
  return (typeof window !== 'undefined')
}
export interface CartAddon {
  open: boolean
}

const CART_DEFAULT_STATE = {
  open: false,
}

const _data = makeVar<CartAddon>({ ...CART_DEFAULT_STATE })

export class CartPlugin implements ConfigPlugin {

  static instance: CartPlugin

  static shared(): CartPlugin {
    if (!this.instance) {
      this.instance = new CartPlugin()
    }
    return this.instance
  }

  client!: ApolloClient<NormalizedCacheObject>

  open(): void {
    _data({ open: true })
  }

  close(): void {
    _data({ open: false })
  }

  diffCartQuantities(current: CartQuantity, previous: CartQuantity) {
    const cache = this.client.cache
    const allItems: CartQuantity = { ...previous, ...current }
    Object.keys(allItems).forEach((sku) => {
      const product = {
        sku,
        __typename: allItems[sku].type,
      }
      cache.evict({ id: cache.identify(product as unknown as StoreObject), fieldName: 'quantityInCart' })
      cache.evict({ id: cache.identify(product as unknown as StoreObject), fieldName: 'cartItemUid' })
    })
  }

  async configure(config: Config): Promise<void> {
    const client = await config.getClient()
    this.client = client
  }

  typePolicies = (): TypePolicies => ({
    Cart: {
      fields: {
        open: {
          read: (current: boolean, options: FieldFunctionOptions): boolean => {
            if (isBrowser()) {
              const previousCartQuantities = JSON.parse(sessionStorage.getItem('CART_QUANTITIES') || '{}')
              const currentCartQuantities: { [k: string]: { quantity: number, cartItemUid: string, type: string } } = {}
              const items = options.readField('items') as readonly Reference[]
              for (let i = 0; i < items.length; i++) {
                const itemRef = items[i]
                const cartItemUid = options.readField('uid', itemRef) as string
                const productRef = options.readField('product', itemRef) as Reference
                const sku = options.readField('sku', productRef) as string
                const type = options.readField('__typename', productRef) as string
                const quantity = options.readField('quantity', itemRef) as number
                currentCartQuantities[sku] = { quantity, cartItemUid, type }
                const variantRef = options.readField('configuredVariant', itemRef) as Reference
                if (variantRef) {
                  const variantSku = options.readField('sku', variantRef) as string
                  currentCartQuantities[variantSku] = { quantity, cartItemUid, type: 'SimpleProduct' }
                }
              }
              sessionStorage.setItem('CART_QUANTITIES', JSON.stringify(currentCartQuantities))
              sessionStorage.setItem('PREVIOUS_CART_QUANTITUES', JSON.stringify(previousCartQuantities))
              setTimeout(() => {
                if (JSON.stringify(currentCartQuantities) !== JSON.stringify(previousCartQuantities)) {
                  this.diffCartQuantities(currentCartQuantities, previousCartQuantities)
                }
              }, 100)
              return _data().open
            }
            return false
          },
        },
        items: {
          merge(_existing: CartItemFragment[], incoming: CartItemFragment[]): CartItemFragment[] {
            return incoming
          },
        },
      },
    },
    AvailableShippingMethod: {
      fields: {
        id: {
          read(obj: ShippingMethodFragment, options: FieldFunctionOptions): string {
            const carrierCode = options.readField('carrierCode') as string
            const methodCode = options.readField('methodCode') as string
            return `${carrierCode}_${methodCode}`
          },
        },
      },
    },
    AvailablePaymentMethod: {
      fields: {
        id: {
          read(obj: PaymentMethodFragment, options: FieldFunctionOptions): string {
            const code = options.readField('code') as string
            return code
          },
        },
      },
    },
    CartItem: {
      keyFields: ['uid'],
    },
  })

  extensions = (): DocumentNode => gql`

    extend type Cart {
      open: Boolean!
    }
    
    extend type AvailableShippingMethod {
      id: ID!
    }

    extend type AvailablePaymentMethod {
      id: ID!
    }
  `

}
