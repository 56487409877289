import React, { useState, useEffect } from 'react'

import update from 'react-addons-update'
import styled from 'styled-components'

// TODO REWRITE
import { Button, ButtonColors } from '@atoms/buttons'
import { ResponsivePXValue, theme } from '@components/Theme'
import { useClickOutside } from '@hooks/UseClickOutside'
import { useSimpleToasts } from '@simple/toasts'

const NeedHelpWrapper = styled.div`
  display: block;
  position: relative;
  ${ResponsivePXValue('margin', { mobile: '10px 0', tablet: '20px 0', desktop: '24px 0 24px 0' })}
  .need-help-heading {
    ${ResponsivePXValue('width', { mobile: '100%', tablet: 'fit-content', desktop: 'fit-content' })}
  }
`

const ContactActionClick = styled.div`
 &:hover {
    color : ${(props): string => props.theme.colors.green.greenVogue};
  }
`

const ContactTooltipSpacer = styled.div`
  border-style: solid;
  height: 50%;
  border-color: ${(props): string => props.theme.colors.green.watercourse};
  ${ResponsivePXValue('border-right-width', '1px')}
  ${ResponsivePXValue('width', '1px')}
  ${ResponsivePXValue('margin', ' 0 10px 0 10px')}
`

const ContactTooltipContainer = styled.div`
  position: absolute;
  z-index: 1;
  ${ResponsivePXValue('top', { mobile: '64px', tablet: '80px', desktop: '80px' })}
  ${ResponsivePXValue('left', { mobile: '-1px', tablet: '-10px', desktop: '-10px' })}
  ${ResponsivePXValue('padding-top', { mobile: '10px', tablet: '14px', desktop: '14px' })}

`

const ContactTooltip = styled.div`

 font-family: 'open-sans';
  color : ${(props): string => props.theme.colors.green.greenVogue};
  display: flex;
  align-items: center;
  justify-content: center;
  
  font-weight: 400;
  ${ResponsivePXValue('font-size', { mobile: '10px', tablet: '14px', desktop: '12px' })}
  ${ResponsivePXValue('gap', { mobile: '5px', tablet: '5px', desktop: '5px' })}
  ${ResponsivePXValue('border-radius', '2px')}
  ${ResponsivePXValue('padding', { mobile: '0 10px', tablet: '0 30px', desktop: '0 14px' })}
  ${ResponsivePXValue('height', { mobile: '33px', tablet: '45px', desktop: '45px' })}
  ${ResponsivePXValue('width', { mobile: '290px', tablet: '295px', desktop: '345px' })}
  background-color: ${(props): string => props.theme.colors.green.skeptic};
`

const Close = styled.div`
  cursor: pointer;
  ${ResponsivePXValue('height', { mobile: '15px', tablet: '20px', desktop: '15px' })}
  ${ResponsivePXValue('width', { mobile: '15px', tablet: '20px', desktop: '15px' })}
  ${ResponsivePXValue('margin', { mobile: '0 0 0 10px', tablet: '0', desktop: '0 0 0 10px' })}
`

const IconWrappers = styled.div`
  ${ResponsivePXValue('height', { mobile: '13px', tablet: '13px', desktop: '13px' })}
  ${ResponsivePXValue('width', { mobile: '13px', tablet: '18px', desktop: '18px' })}
`

const Arrow = styled.div`
  position:absolute;
  ${ResponsivePXValue('top', { mobile: '0px', tablet: '0px', desktop: '0px' })}
  ${ResponsivePXValue('right', { mobile: '20px', tablet: '20px', desktop: '20px' })}
  ${ResponsivePXValue('height', { mobile: '13px', tablet: '13px', desktop: '16px' })}
  ${ResponsivePXValue('width', { mobile: '20px', tablet: '20px', desktop: '20px' })}
`

const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '8px')}
`
interface InlineSelectInputState {
  open: boolean
  activeTab: number
}

const DEFAULT_STATE = {
  open: false,
  activeTab: 3,
}

export function NeedHelp(): JSX.Element {

  const { addToast } = useSimpleToasts()
  const [state, setState] = useState<InlineSelectInputState>({ ...DEFAULT_STATE })
  const { isOutside, shouldMonitor, ref } = useClickOutside<HTMLDivElement>(true)

  const _copyContent = (text: string): void => {
    navigator.clipboard.writeText(text)
    addToast({
      message: text + ' copied to clipboard.',
      appearance: 'success',
    })
  }

  const needHelpColorObject: ButtonColors = {
    color: theme.colors.black.codGrey,
  }

  useEffect(() => {
    shouldMonitor(state.open)
  }, [state.open])

  useEffect(() => {
    if (isOutside) {
      setState((prevState) => update(prevState, {
        open: { $set: false },
      }))
    }
  }, [isOutside])

  return (
    <NeedHelpWrapper ref={ref}>
      <Button variant='action' colors={needHelpColorObject} title='Need Help?' className='need-help-heading' />
      <ButtonsContainer>
        <Button
          title='CHAT'
          variant='layout'
          fullWidth />
        <Button
          title='CONTACT US'
          variant='layout'
          fullWidth
          href={'/contact-us'} />
      </ButtonsContainer>
    </NeedHelpWrapper>
  )

}
