import React, { useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { useTheme } from 'styled-components'

import { ModalPlugin, GlobalModalTypeEnum } from '@api/local/ModalPlugin'
import { Button } from '@atoms/buttons'
import { Icon, LocalIconEnums } from '@atoms/images'
import { Card, Divider } from '@atoms/layout'
import { Tag, Paragraph, Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { CartFragment, DiscountFragment, CustomerFragment, useGetStoreConfigQuery } from '@hooks/api/index'
import { SiteHelper } from '@lib/SiteHelper'
import { OutOfStockModal } from '@molecules/modals/OutOfStockModal'
import { Table, Row, Cell } from '@molecules/tables'
import { CustomerTypeEnum, ProductStockStatus } from '@uctypes/api/globalTypes'

const Container = styled.div`
  .summary-title {
    ${ResponsivePXValue('margin', '0 0 10px')}
  }
  .seperator-text {
    flex-shrink: 0;
    margin: 0;
  }
  .seperator {
    ${ResponsivePXValue('margin', '16px 0')}
  }
  .card {
    ${ResponsivePXValue('padding', { mobile: '12px 12px 0 12px', tablet: '24px', desktop: '24px' })}
  }
`

const Summary = styled.div`
  ${ResponsivePXValue('padding', '5px 0')}
  .tag {
    width: fit-content;
    ${ResponsivePXValue('margin', '0 0 5px')}
  }
  .total-row {
    ${ResponsivePXValue('margin-top', '12px')}
    ${ResponsivePXValue('padding', { mobile: '12px 0 0', tablet: '12px 0 0', desktop: '12px 0' })}
    ${(props): string => ResponsivePXValue('border-top', `1px solid ${props.theme.colors.grey.gallery}`)}
  }
  .button {
    width: 100%;
  }
`

const Methods = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  .visa {
    ${ResponsivePXValue('width', '40px')}
  }

  .mastercard {
    ${ResponsivePXValue('width', '40px')}
  }

  .ozow {
    ${ResponsivePXValue('width', '60px')}
  }

  .amex {
    ${ResponsivePXValue('width', '25px')}
  }

  .eft {
    ${ResponsivePXValue('width', '35px')}
  }
`

const MethodIcon = styled.div``

export interface CartSummaryProps {
  className?: string
  cart: CartFragment
  customer: CustomerFragment
  includeCheckout?: boolean
}

interface CartSummaryState {
  stock: boolean
}

const DEFAULT_STATE: CartSummaryState = {
  stock: false,
}

export function CartSummary({ className, cart, customer, includeCheckout = true }: CartSummaryProps): JSX.Element {

  const [state, setState] = useState<CartSummaryState>({ ...DEFAULT_STATE })
  const { data: storeData } = useGetStoreConfigQuery()
  const theme = useTheme()
  const navigate = useNavigate()
  // TODO: filter
  const outOfStock = cart?.items?.filter((item) => item.product.stockStatus === ProductStockStatus.OUT_OF_STOCK) || []

  const totalRewardPoints = cart?.amastyRewardsHighlights?.captionText || 0
  const showRewardPoints = cart?.amastyRewardsHighlights?.visible || false

  const _handleCheckout = () => {
    if (outOfStock.length) {
      setState((prevState) => update(prevState, {
        stock: {
          $set: true,
        },
      }))
      return
    }
    if (customer.customerType === CustomerTypeEnum.GUEST) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.LOG_IN)
    } else if (!customer.mobileVerified && storeData?.storeConfig?.otpConfirmationRequired) {
      ModalPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.VALIDATE_MOBILE)
    } else {
      navigate('/checkout')
    }
  }

  const _handleOutOfStockDone = (): void => {
    setState((prevState) => update(prevState, {
      stock: {
        $set: false,
      },
    }))
  }

  let discount: DiscountFragment
  const deliveryValue = cart?.shippingAddresses?.[0]?.selectedShippingMethod
    ? cart?.shippingAddresses?.[0]?.selectedShippingMethod?.priceInclTax?.value === 0
      ? 'Free'
      : SiteHelper.formatCurrency(cart?.shippingAddresses?.[0]?.selectedShippingMethod?.priceInclTax)
    : '-- --'

  return (
    <Container className={className}>
      <OutOfStockModal cartId={cart?.id} cartItems={outOfStock} open={state.stock} onClose={_handleOutOfStockDone} />
      <Card className='card'>
        <Heading variant='h4' className='summary-title'>Order Summary</Heading>
        <Summary>
          <Table>
            <Row>
              <Cell><Paragraph variant='p1' className='tag'>Delivery</Paragraph></Cell>
              <Cell align='end'><Paragraph variant='p1' className='tag'>{deliveryValue}</Paragraph></Cell>
            </Row>
            <If condition={showRewardPoints}>
              <Row>
                <Cell><Paragraph variant='p1' className='tag'>You will earn</Paragraph></Cell>
                <Cell align='end'><Paragraph variant='p1' className='tag'>{totalRewardPoints} Faithful Points</Paragraph></Cell>
              </Row>
            </If>
            <For each='discount' of={cart?.prices?.discounts || []}>
              <Row key={discount.label}>
                <Cell><Paragraph variant='p1' className='tag' color={theme.colors.red.cinnabar}>{discount.label}</Paragraph></Cell>
                <Cell align='end'><Paragraph variant='p1' className='tag' color={theme.colors.red.cinnabar}>-{SiteHelper.formatCurrency(discount.amount)}</Paragraph></Cell>
              </Row>
            </For>
            <Row className='total-row'>
              <Cell><Paragraph variant='p1' bold className='tag'>Total</Paragraph></Cell>
              <Cell align='end'><Heading variant='h2' className='tag'>{(cart?.prices?.grandTotal) ? SiteHelper.formatCurrency(cart.prices.grandTotal) : 0}</Heading></Cell>
            </Row>
          </Table>
          <If condition={includeCheckout}>
            <Button className='button' size='medium' variant='primary' title='CHECKOUT NOW' onClick={_handleCheckout} />
          </If>
        </Summary>
        <If condition={includeCheckout}>
          <Divider color={theme.colors.grey.gallery} className='seperator'>
            <Tag variant='t2' className='seperator-text' color={theme.colors.green.greenVogue}>We Accept:</Tag>
          </Divider>
          <Methods>
            <MethodIcon className='visa'>
              <Icon icon={LocalIconEnums.PAYMENT_VISA_COLOR} />
            </MethodIcon>
            <MethodIcon className='mastercard'>
              <Icon icon={LocalIconEnums.PAYMENT_MASTER_CARD_COLOR} />
            </MethodIcon>
            <MethodIcon className='amex'>
              <Icon icon={LocalIconEnums.PAYMENT_AMERICAN_EXPRESS_COLOR} />
            </MethodIcon>
            <MethodIcon className='ozow'>
              <Icon icon={LocalIconEnums.PAYMENT_OZOW_COLOR} />
            </MethodIcon>
            <MethodIcon className='eft'>
              <Icon icon={LocalIconEnums.PAYMENT_EFT_COLOR} />
            </MethodIcon>
          </Methods>
        </If>
      </Card>
    </Container>
  )

}
