import React from 'react'

import styled, { useTheme } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local/AppPlugin'
import { Button } from '@atoms/buttons'
import { ResponsiveImage } from '@atoms/images'
import { Heading } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useGetAppQuery, PageBlockFragment } from '@hooks/api/index'
import { DeviceTypeEnum, PageHorizontalAlignment } from '@uctypes/api/globalTypes'

import { HTML } from './HTML'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const HeadingSection = styled.div<{ backgroundColor: string, alignment: string }>`
  width: 100%;
  height: 80px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${({ alignment }): string => alignment === PageHorizontalAlignment.LEFT
    ? 'flex-start'
    : alignment === PageHorizontalAlignment.RIGHT
      ? 'flex-end'
      : 'center'};
  ${ResponsivePXValue('gap', { mobile: '12px', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '16px 24px', desktop: '16px 24px' })}
  background-color: ${(props): string => props.backgroundColor};
`

const IconContainer = styled.div`
  ${ResponsivePXValue('width', { mobile: '48px', tablet: '48px', desktop: '48px' })}
  ${ResponsivePXValue('height', { mobile: '48px', tablet: '48px', desktop: '48px' })}
`

const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  ${ResponsivePXValue('gap', { mobile: '12px', tablet: '24px', desktop: '24px' })}
  ${ResponsivePXValue('padding', { mobile: '16px', tablet: '24px', desktop: '24px' })}
  background-color: ${({ theme }) => theme.colors.white.pureWhite};
  height: 100%;
`

const ButtonContainer = styled.div<{ alignment: string }>`
  display: flex;
  width: 100%;
  ${({ alignment }) => ResponsivePXValue('justify-content', {
    mobile: 'center',
    tablet: alignment === PageHorizontalAlignment.LEFT
      ? 'flex-start'
      : alignment === PageHorizontalAlignment.RIGHT
        ? 'flex-end'
        : 'center',
    desktop: alignment === PageHorizontalAlignment.LEFT
      ? 'flex-start'
      : alignment === PageHorizontalAlignment.RIGHT
        ? 'flex-end'
        : 'center',
  })}
`

export interface PageCardBlockProps {
  block: PageBlockFragment
}

export function PageCardBlock({ block }: PageCardBlockProps): JSX.Element {

  const { backgroundColor, horizontalAlignment, image, foregroundColor, title, description, link } = block
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()
  const { MOBILE } = DeviceTypeEnum
  const theme = useTheme()

  return (
    <Container>
      <HeadingSection
        backgroundColor={backgroundColor}
        alignment={horizontalAlignment}>
        <IconContainer>
          <ResponsiveImage image={image} />
        </IconContainer>
        <Heading
          variant='h2'
          color={foregroundColor}>
          {title}
        </Heading>
      </HeadingSection>
      <ContentSection>
        <HTML color={theme.colors.green.bottleGreen}>
          {description}
        </HTML>
        <If condition={!!link}>
          <ButtonContainer alignment={horizontalAlignment}>
            <Button variant='ghost'
              fullWidth={appData.app.deviceType === MOBILE}
              title={link.title}
              href={link.url} />
          </ButtonContainer>
        </If>
      </ContentSection>
    </Container>
  )

}
