import React, { useState, useEffect } from 'react'

import useEmblaCarousel from 'embla-carousel-react'
import update from 'react-addons-update'
import styled from 'styled-components'

import { DotPagination } from '@atoms/controls'
import { ResponsiveImage } from '@atoms/images'
import { ResponsivePXValue } from '@components/Theme'
import { ProductDetailsImageFragment } from '@hooks/api/index'
import { ProductImageGalleryModal } from '@molecules/modals/ProductImageGalleryModal'

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .controls {
    ${ResponsivePXValue('margin', '20px 0')}
  }
`

const Embla = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  ${ResponsivePXValue('height', { mobile: '200px' })}
`

const EmblaContainer = styled.div`
  position: relative;
  display: flex;
  ${ResponsivePXValue('gap', '8px')}
`

const EmblaSlide = styled.div`
  position: relative;
  user-select: none;
  ${ResponsivePXValue('flex', '0 0 100%')}
  ${ResponsivePXValue('height', { mobile: '200px' })}
`

export interface ProductMobileImagesProps {
  cover: ProductDetailsImageFragment
  gallery: ProductDetailsImageFragment[]
}

interface ProductMobileImagesState {
  scrollOffset: number
  modalOpen: boolean
}

const DEFAULT_STATE: ProductMobileImagesState = {
  scrollOffset: 0,
  modalOpen: false,
}

export function ProductMobileImages({ cover, gallery }: ProductMobileImagesProps): JSX.Element {

  const [state, setState] = useState<ProductMobileImagesState>({ ...DEFAULT_STATE })
  const [emblaRef, emblaApi] = useEmblaCarousel()

  const images: ProductDetailsImageFragment[] = [cover, ...gallery]

  const _handleModalClose = (): void => {
    setState((prevState) => update(prevState, {
      modalOpen: { $set: false },
    }))
  }

  const _handleModalOpen = (): void => {
    setState((prevState) => update(prevState, {
      modalOpen: { $set: true },
    }))
  }

  const _handleChange = (index: number): void => {
    setState((prevState) => update(prevState, {
      scrollOffset: { $set: index },
    }))
  }

  const onSelect = () => {
    setState((prevState) => update(prevState, {
      scrollOffset: { $set: emblaApi.selectedScrollSnap() },
    }))
  }

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(state.scrollOffset)
    }
  }, [state.scrollOffset])

  useEffect(() => {
    if (emblaApi) {
      emblaApi.scrollTo(state.scrollOffset)
      emblaApi.on('select', onSelect)
    }
  }, [emblaApi])

  let image: ProductDetailsImageFragment
  let imageIndex: number

  return (
    <>
      <ProductImageGalleryModal
        images={images}
        open={state.modalOpen}
        onClose={_handleModalClose} />
      <Container>
        <Embla ref={emblaRef}>
          <EmblaContainer>
            <For each='image' index='imageIndex' of={images}>
              <EmblaSlide
                key={`${image.id}-${imageIndex}`}
                onClick={_handleModalOpen}>
                <ResponsiveImage image={image} objectFit='contain' />
              </EmblaSlide>
            </For>
          </EmblaContainer>
        </Embla>
        <DotPagination
          className='controls'
          currentIndex={state.scrollOffset}
          total={images.length}
          onChange={_handleChange} />
      </Container>
    </>
  )

}
