import React, { useEffect, useRef, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import styled, { LayoutSizeClass, useTheme } from 'styled-components'

import { FavouriteButton } from '@atoms/buttons'
import { ResponsiveImage } from '@atoms/images'
import { ProductLabel, ProductLabels, Rating } from '@atoms/index'
import { layoutColWidthForGrid, Skeleton, SkeletonNode } from '@atoms/layout'
import { Link, Paragraph, Tag, Title } from '@atoms/typography'
import { ResponsivePXValue } from '@components/Theme'
import { useChangeCartItemQuantityMutation, useRemoveItemFromCartMutation, ProductListFragment, useAddProductToCartMutation, WarehouseFragment, BreadcrumbFragment, useAddBundleProductToCartMutation, BundleProductListFragment } from '@hooks/api/index'
import { useCartId } from '@hooks/UseCartId'
import { SiteHelper } from '@lib/SiteHelper'
import { useSimpleToasts } from '@simple/toasts'
import { BundleOptionInput, ProductStockStatus } from '@uctypes/api/globalTypes'

import { AddToCartButton, AddToCartDisplayTypeEnum } from './AddToCartButton'

const QUANTITY_CHANGE_TIMEOUT = 2000

const Container = styled.div<{ size: LayoutSizeClass }>`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  background-color: ${(props): string => props.theme.colors.white.pureWhite};
  ${ResponsivePXValue('padding', '12px')}
  ${ResponsivePXValue('height', { mobile: '171px', tablet: '171px', desktop: '171px' })}

  .brand-name {
    ${ResponsivePXValue('margin-top', '4px')}
  }
`

const UpperContainer = styled.div`
  flex-grow: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`

const LowerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  .button {
    flex-grow: 0;
    flex-shrink: 0;
    ${ResponsivePXValue('width', '176px')}
  }
`

const CardLink = styled.a`
  text-decoration: none;
  white-space: normal;

  .elipsis {
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    ${ResponsivePXValue('-webkit-line-clamp', { mobile: '3', tablet: '5', desktop: '5' })}
    ${ResponsivePXValue('padding-bottom', '2px')}
    margin: 0;
  }
`

const ImageContainer = styled.div<{ size: LayoutSizeClass }>`
  flex-shrink: 0;
  position: relative;
  ${ResponsivePXValue('width', { mobile: '94px', tablet: '100px', desktop: '120px' })}
  ${ResponsivePXValue('height', { mobile: '94px', tablet: '100px', desktop: '120px' })}
  ${ResponsivePXValue('margin', { mobile: '0 10px 10px 0', tablet: '0 15px 15px 0', desktop: '0 20px 20px 0' })}
  
`

const ContentContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  ${ResponsivePXValue('margin-left', '10px')}
`

const TopSection = styled.div`
  display: flex;
  justify-content: center;
  
  .product-card-favourite {
    flex-shrink: 0;
    ${ResponsivePXValue('margin-left', '5px')}
    ${ResponsivePXValue('display', { desktop: 'none' })}
  }
`

const BrandContainer = styled.div`
  flex-grow: 1;
  width: 100%;
  ${ResponsivePXValue('padding-top', '4px')}
`

const RatingAndStockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${ResponsivePXValue('padding-top', '4px')}
`

const RatingContainer = styled.div`
  display: flex;
  align-items: center;
  ${ResponsivePXValue('gap', '5px')}
`

const StockContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '6px')}
  ${ResponsivePXValue('margin', '6px 0 4px')}

  .stock-label {
    flex-shrink: 0;
  }
`

const StockItem = styled.div`
  ${ResponsivePXValue('flex-grow', { mobile: '1', tablet: '0', desktop: '0' })}
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'open-sans';
  font-weight: 600;
  ${ResponsivePXValue('font-size', { mobile: '9px', tablet: '11px', desktop: '11px' })}
  background-color: ${(props): string => props.theme.colors.grey.athens};
  color: ${(props): string => props.theme.colors.grey.stormDust};
  ${ResponsivePXValue('padding', { mobile: '6px 4px', tablet: '8px', desktop: '8px' })}
`

const PriceContainer = styled.div`
  flex-grow: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end; 
  align-items: flex-start;
`

const LoadingOverlay = styled.div<{ active: boolean }>`
  display: ${(props): string => props.active ? 'flex' : 'none'};
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.white.pureWhite, 0.5)};
`

export interface ProductRowProps {
  product?: ProductListFragment
  disabled?: boolean
  className?: string
  basePath?: string
  overwriteBreadCrumbs?: BreadcrumbFragment[]
}

interface Pricing {
  price: string
  discount: number
}

interface PriceItem {
  maxPrice: Pricing
  minPrice: Pricing
}

interface ProductRowState {
  newAmount: number | null
}

const DEFAULT_STATE: ProductRowState = {
  newAmount: null,
}

export function ProductRow({ product, disabled, className, basePath = '', overwriteBreadCrumbs }: ProductRowProps): JSX.Element {

  const [state, setState] = useState<ProductRowState>({ ...DEFAULT_STATE })
  const [addSimpleProduct, { loading: addLoading }] = useAddProductToCartMutation()
  const [addBundleProduct, { loading: addBundleLoading }] = useAddBundleProductToCartMutation()
  const [removeItemFromCart, { loading: removeLoading }] = useRemoveItemFromCartMutation()
  const [changeQuantity, { loading: quantityLoading }] = useChangeCartItemQuantityMutation()
  const navigate = useNavigate()
  const { addToast } = useSimpleToasts()
  const { cartId } = useCartId()
  const updateTimerRef = useRef(null)
  const theme = useTheme()
  const size = layoutColWidthForGrid({ mobile: 4, tablet: 4, desktop: 2 })

  if (!product) {
    return (
      <Container size={size}>
        <Skeleton
          height={{ mobile: '170px', tablet: '170px', desktop: '170px' }}
          padding={{ mobile: '8px', tablet: '8px', desktop: '8px' }}
          direction='row'
          align='center'
          gap='10px'>
          <SkeletonNode
            direction='column'
            justify='flex-start'
            align='flex-start'
            gap='10px'>
            <SkeletonNode
              color='gallery'
              shape='rounded'
              height={{ mobile: '100px', tablet: '100px', desktop: '100px'}} 
              // width={{ mobile: '40%', tablet: '40%', desktop: '40%'}}
              />
            <SkeletonNode
              color='gallery'
              shape='rounded'
              height={{ mobile: '20px', tablet: '20px', desktop: '20px'}} 
              width={{ mobile: '40%', tablet: '40%', desktop: '40%'}}/>
          </SkeletonNode>
          <SkeletonNode
            direction='column'
            justify='space-between'
            align='flex-start'>
              <SkeletonNode
                direction='column'
                gap='2px'
                align='flex-start'>
                <SkeletonNode 
                  color='gallery'
                  shape='rounded'
                  height={{ mobile: '20px', tablet: '20px', desktop: '20px'}}
                />
                <SkeletonNode 
                  color='gallery'
                  shape='rounded'
                  height={{ mobile: '20px', tablet: '20px', desktop: '20px'}}
                  width={{ mobile: '50%', tablet: '50%', desktop: '50%'}}
                />
              </SkeletonNode>
              <SkeletonNode>
                <SkeletonNode 
                  color='gallery'
                  shape='rounded'
                  height={{ mobile: '30px', tablet: '30px', desktop: '30px'}}
                />
              </SkeletonNode>
          </SkeletonNode>
        </Skeleton>
      </Container>
    )
  }

  const addSimpleProductToCart = async (): Promise<void> => {
    const response = await addSimpleProduct({
      variables: {
        cartId,
        sku: product.sku,
        quantity: 1,
      },
    })
    if (response?.data?.addProduct?.userErrors?.length) {
      response?.data?.addProduct?.userErrors?.forEach?.(({ message }) => addToast({
        message,
        appearance: 'error',
      }))
    }
  }

  const addBundleProductToCart = async (): Promise<void> => {
    const bundleProduct = product as BundleProductListFragment
    const bundleOptions: BundleOptionInput[] = []
    bundleProduct.items.forEach((itm) => {
      itm.options.forEach((opt) => {
        bundleOptions.push({
          id: itm.optionId,
          quantity: opt.quantity,
          value: [opt.id + ''],
        })
      })
    })

    await addBundleProduct({
      variables: {
        input: {
          cartId,
          cartItems: [{
            bundleOptions,
            data: {
              quantity: 1,
              sku: product.sku,
            },
          }],
        },
      },
    })
  }

  const addProduct = async (): Promise<void> => {
    try {
      if (product.__typename === 'BundleProduct') {
        await addBundleProductToCart()
      } else {
        await addSimpleProductToCart()
      }
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const removeProduct = async (): Promise<void> => {
    try {
      await removeItemFromCart({
        variables: {
          cartId,
          cartItemUid: product.cartItemUid,
        },
      })
      setState((prevState) => update(prevState, {
        newAmount: { $set: null },
      }))
    } catch (e) {
      addToast({
        message: e.message,
        appearance: 'error',
      })
    }
  }

  const changeProduct = async (): Promise<void> => {
    updateTimerRef.current = setTimeout(async () => {
      try {
        await changeQuantity({
          variables: {
            cartId,
            cartItemUid: product.cartItemUid,
            quantity: state.newAmount,
          },
        })
      } catch (e) {
        addToast({
          message: e.message,
          appearance: 'error',
        })
      }
    }, QUANTITY_CHANGE_TIMEOUT)
  }

  const updateProduct = async (): Promise<void> => {
    if (updateTimerRef.current) {
      clearTimeout(updateTimerRef.current)
    }
    if (!product.quantityInCart) {
      await addProduct()
    } else if (product.quantityInCart && state.newAmount === 0) {
      removeProduct()
    } else {
      changeProduct()
    }
  }

  const openDetails = (): void => {
    let stateOptions: { state: BreadcrumbFragment[] | null } = {
      state: null,
    }
    if (overwriteBreadCrumbs) {
      stateOptions = {
        state: overwriteBreadCrumbs,
      }
    }
    navigate(`${basePath}/${product.urlKey}`, stateOptions)
  }

  const _handleAddProduct = async (): Promise<void> => {
    if (product.__typename === 'ConfigurableProduct') {
      openDetails()
    } else {
      await addProduct()
    }
  }

  const _handleIncreaseProduct = async (): Promise<void> => {
    const newAmount = state.newAmount === null ? product.quantityInCart + 1 : state.newAmount + 1
    setState((prevState) => update(prevState, {
      newAmount: { $set: newAmount },
    }))
  }

  const _handleDecreaseProduct = async (): Promise<void> => {
    let newAmount = state.newAmount === null ? product.quantityInCart - 1 : state.newAmount - 1
    if (newAmount < 0) {
      newAmount = 0
    }
    setState((prevState) => update(prevState, {
      newAmount: { $set: newAmount },
    }))
  }

  const _handleLink = (e?: React.MouseEvent<HTMLAnchorElement>): void => {
    e?.preventDefault()
    openDetails()
  }

  useEffect(() => {
    if (state.newAmount !== null) {
      updateProduct()
    }
  }, [state.newAmount])

  let price = `${SiteHelper.formatCurrency(product.priceRange.minimumPrice.finalPrice)}`
  let regularPrice = ''

  if (product.__typename === 'ConfigurableProduct' && product.variants?.length) {
    let min = product.variants?.[0]
    product?.variants?.forEach?.((vari) => {
      if (vari.product.priceRange.minimumPrice.finalPrice.value < min.product.priceRange.minimumPrice.finalPrice.value) {
        min = vari
      }
    })
    price = `${SiteHelper.formatCurrency(min.product.priceRange.minimumPrice.finalPrice)}`
  } else if (product.priceRange.maximumPrice.finalPrice.value !== product.priceRange.maximumPrice.regularPrice.value) {
    regularPrice = `${SiteHelper.formatCurrency(product.priceRange.maximumPrice.regularPrice)}`
  }

  let label: ProductListFragment['labels'][0]
  let labelIndex: number

  let warehouse!: WarehouseFragment
  let warehouseIndex!: number

  const loading = addLoading || removeLoading || quantityLoading
  const displayType = product?.stockStatus === ProductStockStatus.OUT_OF_STOCK
    ? AddToCartDisplayTypeEnum.OUT_OF_STOCK
    : product?.quantityInCart
      ? AddToCartDisplayTypeEnum.QUANTITY
      : product?.__typename === 'ConfigurableProduct'
        ? AddToCartDisplayTypeEnum.SELECT_VARIANT
        : AddToCartDisplayTypeEnum.ADD

  

  return (
    <Container size={size} className={className}>
      <UpperContainer>
        <CardLink href={`${basePath}/${product?.urlKey}`} onClick={_handleLink}>
          <ImageContainer
            size={size}>
            <ResponsiveImage image={product?.coverImage} objectFit='contain' />
            <If condition={!!product?.labels && product?.labels?.length > 0}>
              <ProductLabels>
                <For each='label' of={product?.labels || []} index='labelIndex'>
                  {/** TODO: If ever we need to set the max labels for display then it will need to be implemented here. Default is 3 */}
                  <If condition={labelIndex < 3}>
                    <ProductLabel key={labelIndex} color={label.labelForegroundColor} backgroundColor={label.labelColor}>{label.label}</ProductLabel>
                  </If>
                </For>
              </ProductLabels>
            </If>
          </ImageContainer>
        </CardLink>

        <ContentContainer>
          <TopSection>
            <CardLink href={`${basePath}/${product?.urlKey}`} onClick={_handleLink}>
              <Title
                variant='t5'
                className='elipsis'>
                {product.name}
              </Title>
            </CardLink>
            <FavouriteButton product={product} className='product-card-favourite' />
          </TopSection>
          <BrandContainer>
            <If condition={!!product.brand}>
              <Paragraph variant='p2' className='brand-name'>
                <Link className='product-card-brand-link' href={`/brand/${product?.brand?.urlKey}`}>{product?.brand?.name}</Link>
              </Paragraph>
            </If>
          </BrandContainer>
          <RatingAndStockContainer>
            <If condition={!!product?.ratingSummary}>
              <RatingContainer>
                <Rating rating={product.ratingSummary.averageRating} starCount={1} size='large' />
                <Tag variant='t3'>{(product.ratingSummary.averageRating / 20).toFixed(1)} ({product.reviewCount})</Tag>
              </RatingContainer>
            </If>
            <If condition={product.__typename === 'SimpleProduct'}>
              <StockContainer>
                <Paragraph variant='p3' bold className='stock-label'>
                  Stock:
                </Paragraph>
                <For each='warehouse' of={product.stockInfo?.sources || []} index='warehouseIndex'>
                  <StockItem key={warehouseIndex}>
                    {warehouse.location}: {product.stockStatus === ProductStockStatus.IN_STOCK ? warehouse.qty : 0}
                  </StockItem>
                </For>
              </StockContainer>
            </If>
          </RatingAndStockContainer>

        </ContentContainer>
      </UpperContainer>

      <LowerContainer>
        <PriceContainer>
          <Choose>
            <When condition={regularPrice !== ''}>
              <Paragraph
                color={theme.colors.grey.gunSmoke}
                variant='p2'
                className='discount'
                decoration='line-through'>
                {regularPrice}
              </Paragraph>
              <Title variant='t5' className='price'>{price}</Title>
            </When>
            <When condition={product?.__typename === 'ConfigurableProduct'}>
              <Paragraph
                color={theme.colors.grey.gunSmoke}
                variant='p2'
                className='discount'>
                From
              </Paragraph>
              <Title variant='t5' className='price'>{price}</Title>
            </When>
            <Otherwise>
              <Title variant='t5' className='price'>{price}</Title>
            </Otherwise>
          </Choose>
        </PriceContainer>
        <AddToCartButton
          variant='card'
          className='button'
          displayType={displayType}
          currentQuantity={state.newAmount || product?.quantityInCart}
          loading={loading}
          onAdd={_handleAddProduct}
          onIncrease={_handleIncreaseProduct}
          onDecrease={_handleDecreaseProduct} />
      </LowerContainer>
      <LoadingOverlay active={disabled} />
    </Container>
  )

}
