import React, { useEffect } from 'react'

import Cookies from 'js-cookie'
import styled, { useTheme } from 'styled-components'

import { Button, Heading, LocalIconEnums, Message, Paragraph, Seperator } from '@atoms/index'
import { ResponsivePXValue } from '@components/Theme'
import { SuccessResponse as ReactFacebookLoginInfo, FailResponse as ReactFacebookLoginFail } from '@greatsumini/react-facebook-login'
import { SiteHelper } from '@lib/SiteHelper'
import { TextInput, Form, useForm, LogInModalDisplayType, LoginModalDisplayStatus, SocialLoginData, SocialLoginType } from '@molecules/index'
import { FacebookLoginButton, GoogleLoginButton } from '@organisms/index'
import { CredentialResponse, TokenResponse, useGoogleOneTapLogin } from '@react-oauth/google'
import { SsoProviderEnum } from '@uctypes/api/globalTypes'

export interface Credentials {
  email: string
  password: string
}

const Container = styled.div`
  width: 100%;

  .form-button {
    width: 100%;
    ${ResponsivePXValue('margin', '12px 0 12px 0')}
  }

  .title {
    ${ResponsivePXValue('margin', '0 0 14px 0')}
    font-weight: 700;
  }

  .input {
    ${ResponsivePXValue('margin-bottom', '9px')}
  }

  .messageClass{
    ${ResponsivePXValue('margin-bottom', '20px')}
  }
`

const SocialButtons = styled.div`
  display: flex;
  position: relative;
  ${ResponsivePXValue('gap', { mobile: '16px', tablet: '16px', desktop: '16px' })}
  ${ResponsivePXValue('height', '40px')}

  .platform-button {
    flex-grow: 1;
    width: 50%;
  }

  .loader {
    margin: auto;
    ${ResponsivePXValue('height', '32px')}
    ${ResponsivePXValue('width', '32px')}
  }
`

const SocialButtonsOverlay = styled.div`
   position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: ${(props): string => SiteHelper.getOpaqueColor(props.theme.colors.white.pureWhite, 0.5)};
  display: flex;
  align-items: center;
  justify-content: center;
`

const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('gap', '6px')}
  ${ResponsivePXValue('height', '68px')}
  ${ResponsivePXValue('margin-bottom', '-24px')}

`
export interface LogInData {
  email: string
  password: string
}

export interface SocialLogInData {
  provider: string
  accessToken: string
  accessType: string
}

export interface LogInFormProps {
  onLogInWithCredentials: (credentials: Credentials) => void
  onSocialLogin: (data: SocialLoginData) => void
  onSetDisplayType: (displayType: LogInModalDisplayType) => void
  onSocialLoginError: (error: string) => void
  displayStatus: LoginModalDisplayStatus
  errorMessage?: string
}

export function LogInForm({ onLogInWithCredentials, onSocialLogin, onSocialLoginError, onSetDisplayType, displayStatus, errorMessage }: LogInFormProps): JSX.Element {

  const form = useForm()
  const theme = useTheme()

  const _handleLogin = async (credentials: Credentials): Promise<void> => {
    onLogInWithCredentials(credentials)
  }

  const _handleGoogleOneTapLogIn = async (user: CredentialResponse): Promise<void> => {
    const token = user?.credential
    _handleSocialLogin(token, SsoProviderEnum.GOOGLE_ONE_TAP, 'id_token')
  }

  const _handleGoogleLogIn = async (user: TokenResponse): Promise<void> => {
    const token = user?.access_token
    _handleSocialLogin(token, SsoProviderEnum.GOOGLE)
  }

  const _handleFacebookLogIn = async (res: ReactFacebookLoginInfo): Promise<void> => {
    const token = res?.accessToken
    _handleSocialLogin(token, SsoProviderEnum.FACEBOOK)
  }

  const _handleSocialLogin = async (token: string, provider: SsoProviderEnum, accessType?: string): Promise<void> => {
    onSocialLogin({ token, provider, accessType, loginType: SocialLoginType.LOG_IN })
  }

  const _handleKeyDown = (e: KeyboardEvent): void => {
    const charCode = e.key || e.code
    if (charCode === 'Enter') {
      form.submit()
    }
  }

  useEffect(() => {
    document.addEventListener('keydown', _handleKeyDown)
    return () => document.removeEventListener('keydown', _handleKeyDown)
  }, [])

  useEffect(() => {
    if (Cookies.get('email')) {
      form.setFieldsValue({ email: Cookies.get('email') })
      form?.focus?.('password')
    } else {
      form?.focus?.('email')
    }
  }, [])

  useGoogleOneTapLogin({
    onSuccess: user => _handleGoogleOneTapLogIn(user),
    onError: () => onSocialLoginError('Google One Tap Login Failed'),
  })

  const buttonTitle = displayStatus === LoginModalDisplayStatus.INPUT || displayStatus === LoginModalDisplayStatus.LOADING
    ? 'LOGIN'
    : ''
  const buttonIcon = displayStatus === LoginModalDisplayStatus.SUCCESS
    ? LocalIconEnums.CHECK
    : false

  const loading = displayStatus === LoginModalDisplayStatus.LOADING
  const disabled = displayStatus === LoginModalDisplayStatus.SUCCESS

  return (
    <Container>
      <Heading className='title' variant='h3' >Login</Heading>
      <Form form={form} onFinish={_handleLogin} loading={loading} disabled={disabled}>
        <TextInput
          variant='email'
          showLabel
          label='Email address'
          name='email'
          placeholder='Email address'
          rules={[{ required: true, message: 'Please enter an email address' }]} className='input' />
        <TextInput
          variant='password'
          showLabel
          label='Password'
          name='password'
          placeholder='Password'
          rules={[{ required: true, message: 'Please enter a password' }]} className='input' />
        <If condition={displayStatus === LoginModalDisplayStatus.ERROR}>
          <Message
            wrapperClassName='messageClass'
            backgroundColor={theme.colors.pink.bridesmaid}
            color={theme.colors.red.cinnabar}
            message={errorMessage} />
        </If>
        <Button
          variant='text'
          size='medium'
          title='Forgot password?'
          disabled={loading || disabled}
          onClick={() => onSetDisplayType(LogInModalDisplayType.FORGOT_PASSWORD)} />
        <Button
          loading={loading}
          variant='primary'
          fullWidth
          className='form-button'
          title={buttonTitle}
          onClick={() => disabled ? null : form.submit()}
          icon={buttonIcon} />
        <Seperator align='horizontal' color={theme.colors.white.pampas} />
        <SocialButtons>
          <GoogleLoginButton
            onSuccess={_handleGoogleLogIn}
            onError={(error: TokenResponse) => onSocialLoginError(error.error_description)} />
          <FacebookLoginButton
            onSuccess={_handleFacebookLogIn}
            onFail={(error: ReactFacebookLoginFail) => {
              const errorMessage = error.status === 'loginCancelled'
                ? 'User cancelled the request'
                : 'Facebook internal error, please try again later'
              onSocialLoginError(errorMessage)
            }} />
          <If condition={loading || disabled}>
            <SocialButtonsOverlay />
          </If>
        </SocialButtons>
      </Form>
      <Footer>
        <Paragraph
          display='inline'
          align='center'
          variant='p2'>
            Not part of the Faithful community?
        </Paragraph>
        <Button
          loading={loading || disabled}
          variant='text'
          size='medium'
          title='Sign Up'
          onClick={() => disabled ? null : onSetDisplayType(LogInModalDisplayType.SIGN_UP)} />
      </Footer>
    </Container>
  )
}
