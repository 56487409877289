export interface FooterLinkItem {
  title: string
  link: string
  target?: string
}
export interface FooterLinkGroup {
  title: string
  subs: FooterLinkItem[]
}
export const links: FooterLinkGroup[] = [
  {
    title: 'Faithful to Nature',
    subs: [
      {
        title: 'About Us',
        link: '/about-us',
      },
      {
        title: 'BCorp Certified',
        link: 'https://www.bcorporation.net/en-us/find-a-b-corp/company/faithful-to-nature/',
        target: '_blank',
      }, {
        title: 'Our Products',
        link: '/brands',
      }, {
        title: 'Our Brands',
        link: '/brands',
      }, {
        title: 'Retail Stores',
        link: '/retail-stores',
      }, {
        title: 'Gift Cards',
        link: '/gift-vouchers',
      },
    ],
  }, {
    title: 'Things We Care About',
    subs: [
      {
        title: 'Product Listing Policy',
        link: '/ingredient-policy-information',
      }, {
        title: 'Product Values',
        link: '/values-listing',
      }, {
        title: 'Our Initiatives',
        link: '/earth-child',
      }, {
        title: 'Carbon Neutral Shipping',
        link: '/carbon-neutral',
      }, {
        title: 'The Bad Bunch',
        link: '/bad-bunch',
      },

    ],
  }, {
    title: 'Help',
    subs: [
      {
        title: 'FAQs',
        link: '/faq',
      }, {
        title: 'Refunds & Returns',
        link: '/faq?section=Returns',
      }, {
        title: 'Delivery Options',
        link: '/faq?section=Delivery',
      }, {
        title: 'Payment Options',
        link: '/faq?section=Payments',
      }, {
        title: 'Faithful Points',
        link: '/me/points?section=Do Faithful Points expire?',
      },
    ],
  },
]
