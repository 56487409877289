import { ApolloClient, DocumentNode, FieldPolicy, NormalizedCacheObject, gql, makeVar } from '@apollo/client'

import update from 'react-addons-update'
import { v4 } from 'uuid'

import { PageBuilderFragment } from '@hooks/api/index'
import { Config, ConfigPlugin } from '@lib/Config'
import { DeviceTypeEnum } from '@uctypes/api/globalTypes'

const getDefaultSizeClass = (): DeviceTypeEnum => {
  if (typeof window !== 'undefined' && sessionStorage.getItem('PAGE_BUILDER_DEVICE_TYPE')) {
    return sessionStorage.getItem('PAGE_BUILDER_DEVICE_TYPE') as DeviceTypeEnum
  }
  return DeviceTypeEnum.MOBILE
}

export const PAGE_BUILDER_DEFAULT_STATE: PageBuilderFragment = {
  id: v4(),
  contentId: null,
  contentFetchId: v4(),
  slaveReady: false,
  isMaster: false,
  isEditing: false,
  groupEditingSections: null,
  editingSectionGroup: null,
  editingSection: null,
  editingSectionType: null,
  newSection: false,
  newSectionGroup: false,
  loading: false,
  colapsedSectionGroups: [],
  colapsedSections: [],
  showHidden: false,
  hiddenSectionCount: 0,
  sizeClass: getDefaultSizeClass(),
  sessionId: '',
  __typename: 'PageBuilder',
}

const _data = makeVar<PageBuilderFragment>({ ...PAGE_BUILDER_DEFAULT_STATE })

export class PageBuilderPlugin implements ConfigPlugin {

  static instance: PageBuilderPlugin

  static shared(): PageBuilderPlugin {
    if (!this.instance) {
      this.instance = new PageBuilderPlugin()
    }
    return this.instance
  }

  bc: BroadcastChannel
  client: ApolloClient<NormalizedCacheObject>

  constructor() {
    if (typeof window !== 'undefined') {
      this.bc = new BroadcastChannel('page-builder')
      this.bc.addEventListener('message', (message) => {
        if (message.data?.sessionId === _data().sessionId) {
          this.parseMessage(message.data)
        } else {
          console.log(`RECEIVED BROADCAST WITH WRONG SESSION MASTER: ${_data().isMaster}`)
          console.log(JSON.stringify(message.data, null, 2))
        }
      })
    }
  }

  async configure?(config: Config): Promise<void> {
    this.client = await config.getClient()
  }

  parseMessage(data: any): void {
    console.log(`RECEIVED BROADCAST MASTER: ${_data().isMaster}`)
    console.log(JSON.stringify(data, null, 2))
    if (data.messageType === 'UPDATE') {
      _data({ ..._data(), ...data.builderData })
    } else if (data.messageType === 'SECTION_GROUP_ORDER_CHANGE') {
      this.updateSectionGroupOrder(data.items)
    } else if (data.messageType === 'SECTION_ORDER_CHANGE') {
      this.updateSectionOrder(data.items)
    } else if (data.messageType === 'REMOVE_SECTION_GROUP') {
      this.removeSectionGroup(data.id)
    } else if (data.messageType === 'REMOVE_SECTION') {
      this.removeSection(data.id)
    } else if (data.messageType === 'SLAVE_READY' && _data().isMaster) {
      this.postMessage({ messageType: 'UPDATE', builderData: { ..._data(), isMaster: false } })
    }
  }

  postMessage(message: any): void {
    if (typeof window !== 'undefined') {
      this.bc.postMessage({ ...message, sessionId: _data().sessionId })
    }
  }

  broadcast(data: any) {
    this.postMessage({ messageType: 'UPDATE', builderData: data })
  }

  setSessionId(sessionId: string): void {
    _data(update(_data(), {
      sessionId: {
        $set: sessionId,
      },
    }))
  }

  setSelectedPage(pageId: string): void {
    window.top.postMessage({ type: 'SELECT_PAGE', id: pageId, sessionId: _data().sessionId }, '*')
    this.postMessage({ messageType: 'SELECT_PAGE', id: pageId })
  }

  setUpdatedSectionGroupOrder(items: string[]): void {
    this.postMessage({ messageType: 'SECTION_GROUP_ORDER_CHANGE', items })
  }

  async updateSectionGroupOrder(items: string[]): Promise<void> {
    const client = this.client
    this.setLoading(true)
    await client.mutate({
      mutation: gql`mutation UpdatePageSections($id: ID!, $sections: [ID!]!) { updateContent(id: $id, input: { sectionGroups: $sections }) { id } }`,
      variables: {
        id: _data().contentId,
        sections: items,
      },
    })
    await this.reloadContent()
    this.setLoading(false)
  }

  setUpdatedSectionOrder(items: string[]): void {
    this.postMessage({ messageType: 'SECTION_ORDER_CHANGE', items })
  }

  async updateSectionOrder(items: string): Promise<void> {
    const client = this.client
    this.setLoading(true)
    await client.mutate({
      mutation: gql`mutation UpdateSectionGroupSections($id: ID!, $sections: [ID!]!) { updatePageSectionGroup(id: $id, input: { sections: $sections }) { id } }`,
      variables: {
        id: _data().groupEditingSections,
        sections: items,
      },
    })
    await this.reloadContent()
    this.setLoading(false)
  }

  reloadContent(): void {
    const contentFetchId = v4()
    _data(update(_data(), {
      contentFetchId: {
        $set: contentFetchId,
      },
    }))
    this.broadcast({ contentFetchId })
  }

  setShouldRemoveSectionGroup(id: string): void {
    this.postMessage({ messageType: 'REMOVE_SECTION_GROUP', id })
  }

  async removeSectionGroup(sectionGroupId: string): Promise<void> {
    const client = this.client
    this.setLoading(true)
    await client.mutate({
      mutation: gql`mutation RemovePageSectionGroup($id: ID!) { removePageSectionGroup(id: $id) }`,
      variables: {
        id: sectionGroupId,
      },
      fetchPolicy: 'network-only',
      context: {
        headers: {
          'cache-control': 'no-cache',
          'clear-cache': `Content:${_data().contentId} PageSectionGroup:${_data().groupEditingSections}`,
        },
      },
    })
    await this.reloadContent()
    this.setLoading(false)
  }

  setShouldRemoveSection(id: string): void {
    this.postMessage({ messageType: 'REMOVE_SECTION', id })
  }

  async removeSection(sectionId: string): Promise<void> {
    const client = this.client
    this.setLoading(true)
    const groupData = await client.query({
      query: gql`query GetSectionGroupSections($id: ID!) { onePageSectionGroup(filters: { id: { equals: $id } }) { id sections { id } } }`,
      variables: {
        id: _data().groupEditingSections,
      },
      fetchPolicy: 'network-only',
      context: {
        headers: {
          'cache-control': 'no-cache',
          'clear-cache': `Content:${_data().contentId} PageSectionGroup:${_data().groupEditingSections} PageSection:${sectionId}`,
        },
      },
    })
    const currentSections = groupData.data.onePageSectionGroup.sections.map((section: any) => section.id)
    await client.mutate({
      mutation: gql`mutation UpdateSectionGroupSections($id: ID!, $sections: [ID!]!) { updatePageSectionGroup(id: $id, input: { sections: $sections }) { id } }`,
      variables: {
        id: _data().groupEditingSections,
        sections: currentSections.filter((section: string) => section !== sectionId),
      },
      context: {
        headers: {
          'cache-control': 'no-cache',
          'clear-cache': `Content:${_data().contentId} PageSectionGroup:${_data().groupEditingSections} PageSection:${sectionId}`,
        },
      },
    })
    await this.reloadContent()
    this.setLoading(false)
  }

  async addSection(sectionId: string): Promise<void> {
    const client = this.client
    this.setLoading(true)
    const groupData = await client.query({
      query: gql`query GetSectionGroupSections($id: ID!) { onePageSectionGroup(filters: { id: { equals: $id } }) { id sections { id } } }`,
      variables: {
        id: _data().groupEditingSections,
      },
      fetchPolicy: 'network-only',
      context: {
        headers: {
          'cache-control': 'no-cache',
          'clear-cache': `Content:${_data().contentId} PageSectionGroup:${_data().groupEditingSections} PageSection:${sectionId}`,
        },
      },
    })
    const currentSections = groupData.data.onePageSectionGroup.sections.map((section: any) => section.id)
    await client.mutate({
      mutation: gql`mutation UpdateSectionGroupSections($id: ID!, $sections: [ID!]!) { updatePageSectionGroup(id: $id, input: { sections: $sections }) { id } }`,
      variables: {
        id: _data().groupEditingSections,
        sections: [...currentSections, sectionId],
      },
      context: {
        headers: {
          'cache-control': 'no-cache',
          'clear-cache': `Content:${_data().contentId} PageSectionGroup:${_data().groupEditingSections} PageSection:${sectionId}`,
        },
      },
    })
    await this.reloadContent()
    this.setLoading(false)
  }

  async setLastSection(sectionId: string): Promise<void> {
    const client = this.client
    this.setLoading(true)
    const groupData = await client.query({
      query: gql`query GetSectionGroupSections($id: ID!) { onePageSectionGroup(filters: { id: { equals: $id } }) { id sections { id } } }`,
      variables: {
        id: _data().groupEditingSections,
      },
      fetchPolicy: 'network-only',
      context: {
        headers: {
          'cache-control': 'no-cache',
          'clear-cache': `Content:${_data().contentId} PageSectionGroup:${_data().groupEditingSections}`,
        },
      },
    })
    const currentSections = groupData.data.onePageSectionGroup.sections
      .map((section: any) => section.id)
      .filter((id: string) => id !== sectionId)
    await client.mutate({
      mutation: gql`mutation UpdateSectionGroupSections($id: ID!, $sections: [ID!]!) { updatePageSectionGroup(id: $id, input: { sections: $sections }) { id } }`,
      variables: {
        id: _data().groupEditingSections,
        sections: [...currentSections, sectionId],
      },
      context: {
        headers: {
          'cache-control': 'no-cache',
          'clear-cache': `Content:${_data().contentId} PageSectionGroup:${_data().groupEditingSections} PageSection:${sectionId}`,
        },
      },
    })
    await this.reloadContent()
    this.setLoading(false)
  }

  async setLastSectionGroup(sectionGroupId: string): Promise<void> {
    const client = this.client
    this.setLoading(true)
    const pageData = await client.query({
      query: gql`query GetPageSections($id: ID!) { oneContent(filters: { id: { equals: $id } }) { id sectionGroups { id } } }`,
      variables: {
        id: _data().contentId,
      },
      fetchPolicy: 'network-only',
      context: {
        headers: {
          'cache-control': 'no-cache',
          'clear-cache': `Content:${_data().contentId} PageSectionGroup:${sectionGroupId}`,
        },
      },
    })
    const currentSections = pageData.data.oneContent.sectionGroups
      .map((section: any) => section.id)
      .filter((id: string) => id !== sectionGroupId)
    await client.mutate({
      mutation: gql`mutation UpdatePageSections($id: ID!, $sections: [ID!]!) { updateContent(id: $id, input: { sectionGroups: $sections }) { id } }`,
      variables: {
        id: _data().contentId,
        sections: [...currentSections, sectionGroupId],
      },
      context: {
        headers: {
          'cache-control': 'no-cache',
          'clear-cache': `Content:${_data().contentId} PageSectionGroup:${_data().groupEditingSections}`,
        },
      },
    })
    await this.reloadContent()
    this.setLoading(false)
  }

  setSlaveReady(slaveReady: boolean): void {
    _data(update(_data(), {
      slaveReady: {
        $set: slaveReady,
      },
    }))
    this.postMessage({ messageType: 'SLAVE_READY' })
  }

  setLoading(loading: boolean): void {
    _data(update(_data(), {
      loading: {
        $set: loading,
      },
    }))
    this.broadcast({ loading })
  }

  async setContentId(contentId: string): Promise<void> {
    _data(update(_data(), {
      $set: {
        ...PAGE_BUILDER_DEFAULT_STATE,
        contentId,
        sessionId: _data().sessionId,
        isMaster: _data().isMaster,
      },
    }))
    this.broadcast({ ...PAGE_BUILDER_DEFAULT_STATE, contentId })
  }

  setEditing(): void {
    _data(update(_data(), {
      isEditing: {
        $set: true,
      },
    }))
    this.broadcast({ isEditing: true })
  }

  setIsMaster(isMaster: boolean): void {
    _data(update(_data(), {
      isMaster: {
        $set: isMaster,
      },
    }))
  }

  setSizeClass(sizeClass: DeviceTypeEnum): void {
    _data(update(_data(), {
      sizeClass: {
        $set: sizeClass,
      },
    }))
    this.broadcast({ sizeClass })
  }

  setHiddenSectionCount(hiddenSectionCount: number): void {
    _data(update(_data(), {
      hiddenSectionCount: {
        $set: hiddenSectionCount,
      },
    }))
    this.broadcast({ hiddenSectionCount })
  }

  closeForm(): void {
    _data(update(_data(), {
      newSection: {
        $set: false,
      },
      newSectionGroup: {
        $set: false,
      },
      editingSectionGroup: {
        $set: null,
      },
      editingSection: {
        $set: null,
      },
      editingSectionType: {
        $set: null,
      },
    }))
    this.broadcast({ newSection: false, newSectionGroup: false, editingSectionGroup: null, editingSection: null, editingSectionType: null })
  }

  setNewSection(newSection: boolean): void {
    _data(update(_data(), {
      newSection: {
        $set: newSection,
      },
    }))
    this.broadcast({ newSection })
  }

  setNewSectionGroup(newSectionGroup: boolean): void {
    _data(update(_data(), {
      newSectionGroup: {
        $set: newSectionGroup,
      },
    }))
    this.broadcast({ newSectionGroup })
  }

  setNotEditing(): void {
    _data(update(_data(), {
      isEditing: {
        $set: false,
      },
    }))
    this.broadcast({ isEditing: false })
  }

  setGroupEditingSections(groupEditingSections: string): void {
    _data(update(_data(), {
      groupEditingSections: {
        $set: groupEditingSections,
      },
    }))
    this.broadcast({ groupEditingSections })
  }

  setEditingSectionGroup(editingSectionGroup: string): void {
    _data(update(_data(), {
      editingSectionGroup: {
        $set: editingSectionGroup,
      },
    }))
    this.broadcast({ editingSectionGroup })
  }

  setEditingSection(editingSection: string, editingSectionType: string): void {
    _data(update(_data(), {
      editingSection: {
        $set: editingSection,
      },
      editingSectionType: {
        $set: editingSectionType,
      },
    }))
    this.broadcast({ editingSection, editingSectionType })
  }

  toggleSectionGroupColapse(sectionGroupId: string): void {
    const currentColapsed = [..._data().colapsedSectionGroups]
    if (currentColapsed.includes(sectionGroupId)) {
      currentColapsed.splice(currentColapsed.indexOf(sectionGroupId), 1)
    } else {
      currentColapsed.push(sectionGroupId)
    }
    _data(update(_data(), {
      colapsedSectionGroups: {
        $set: currentColapsed,
      },
    }))
    this.broadcast({ colapsedSectionGroups: currentColapsed })
  }

  toggleSectionColapse(sectionId: string): void {
    const currentColapsed = [..._data().colapsedSections]
    if (currentColapsed.includes(sectionId)) {
      currentColapsed.splice(currentColapsed.indexOf(sectionId), 1)
    } else {
      currentColapsed.push(sectionId)
    }
    _data(update(_data(), {
      colapsedSections: {
        $set: currentColapsed,
      },
    }))
    this.broadcast({ colapsedSections: currentColapsed })
  }

  setShowHidden(showHidden: boolean): void {
    _data(update(_data(), {
      showHidden: {
        $set: showHidden,
      },
    }))
    this.broadcast({ showHidden })
  }

  fieldPolicies = (): { [k: string]: FieldPolicy } => ({
    builder: {
      read(): PageBuilderFragment {
        const data = _data()
        return data
      },
    },
  })

  types = (): DocumentNode => gql`
    enum PageBuilderFormType {
      "Details"
      DETAILS
      "Layout"
      LAYOUT
    }
    type PageBuilder {
      id: ID!
      contentId: String
      contentFetchId: String!
      slaveReady: Boolean!
      isMaster: Boolean!
      isEditing: Boolean!
      groupEditingSections: String
      editingSectionGroup: String
      editingSection: String
      editingSectionType: String
      newSection: Boolean!
      newSectionGroup: Boolean!
      loading: Boolean!
      colapsedSectionGroups: [String!]!
      colapsedSections: [String!]!
      showHidden: Boolean!
      sizeClass: DeviceTypeEnum!
      hiddenSectionCount: Int!
      sessionId: String!
    }
  `

  extensions = (): DocumentNode => gql`
    extend type Query {
      builder: PageBuilder!
    }
  `

  queries = (): DocumentNode => gql`
    fragment PageBuilderFragment on PageBuilder {
      id
      contentId
      contentFetchId
      slaveReady
      isMaster
      isEditing
      groupEditingSections
      editingSectionGroup
      editingSection
      editingSectionType
      newSection
      newSectionGroup
      loading
      colapsedSectionGroups
      colapsedSections
      showHidden
      sizeClass
      hiddenSectionCount
      sessionId
    }
    query GetPageBuilder {
      builder @client {
        ... PageBuilderFragment
      }
    }
  `

}
