import React from 'react'

import { Account } from '@icons/Account'
import { AccountNew } from '@icons/AccountNew'
import { Ae } from '@icons/Ae'
import { Arrow } from '@icons/Arrow'
import { ArrowLeft } from '@icons/ArrowLeft'
import { ArrowRight } from '@icons/ArrowRight'
import { CarbonNeutral } from '@icons/CarbonNeutral'
import { Cart } from '@icons/Cart'
import { CartFilled } from '@icons/CartFilled'
import { CartOutline } from '@icons/CartOutline'
import { Check } from '@icons/Check'
import { ChevronDown } from '@icons/ChevronDown'
import { ChevronFillDown } from '@icons/ChevronFillDown'
import { ChevronFillUp } from '@icons/ChevronFillUp'
import { ChevronLeft } from '@icons/ChevronLeft'
import { ChevronRight } from '@icons/ChevronRight'
import { ChevronUp } from '@icons/ChevronUp'
import { Close } from '@icons/Close'
import { DesktopLogo } from '@icons/DesktopLogo'
import { DoubleTick } from '@icons/DoubleTick'
import { Email } from '@icons/Email'
import { Error } from '@icons/Error'
import { EyeOff } from '@icons/EyeOff'
import { EyeOn } from '@icons/EyeOn'
import { Facebook } from '@icons/Facebook'
import { FacebookSolid } from '@icons/FacebookSolid'
import { FavouriteFilled } from '@icons/FavouriteFilled'
import { FavouriteOutline } from '@icons/FavouriteOutline'
import { Favourites } from '@icons/Favourites'
import { Filter } from '@icons/Filter'
import { FtnLogoFull } from '@icons/FtnLogoFull'
import { Gift } from '@icons/Gift'
import { Google } from '@icons/Google'
import { GoogleFull } from '@icons/GoogleFull'
import { GreyAmex } from '@icons/GreyAmex'
import { GreyEft } from '@icons/GreyEft'
import { GreyMastercard } from '@icons/GreyMastercard'
import { GreyOzow } from '@icons/GreyOzow'
import { GreyVisa } from '@icons/GreyVisa'
import { Grid } from '@icons/Grid'
import { GridView } from '@icons/GridView'
import { HamburgerMenu } from '@icons/HamburgerMenu'
import { HeartFilled } from '@icons/HeartFilled'
import { HeartOutline } from '@icons/HeartOutline'
import { Home } from '@icons/Home'
import { Home_1 } from '@icons/Home_1'
import { HomeLogo } from '@icons/HomeLogo'
import { Instagram } from '@icons/Instagram'
import { LargeCross } from '@icons/LargeCross'
import { List } from '@icons/List'
import { ListView } from '@icons/ListView'
import { Location } from '@icons/Location'
import { LockClosedOutline } from '@icons/LockClosedOutline'
import { Logo } from '@icons/Logo'
import { LogoDesktop } from '@icons/LogoDesktop'
import { LogoIcon } from '@icons/LogoIcon'
import { Mastercard } from '@icons/Mastercard'
import { Minus } from '@icons/Minus'
import { MobileLogo } from '@icons/MobileLogo'
import { Ozow } from '@icons/Ozow'
import { PaymentAmericanExpressColor } from '@icons/PaymentAmericanExpressColor'
import { PaymentEftColor } from '@icons/PaymentEftColor'
import { PaymentMasterCardColor } from '@icons/PaymentMasterCardColor'
import { PaymentOzowColor } from '@icons/PaymentOzowColor'
import { PaymentVisaColor } from '@icons/PaymentVisaColor'
import { PeachPayments } from '@icons/PeachPayments'
import { Phone } from '@icons/Phone'
import { Pinterest } from '@icons/Pinterest'
import { Plus } from '@icons/Plus'
import { PointsOutline } from '@icons/PointsOutline'
import { RadioActive } from '@icons/RadioActive'
import { RadionDefault } from '@icons/RadionDefault'
import { Search } from '@icons/Search'
import { SearchOutline } from '@icons/SearchOutline'
import { Share } from '@icons/Share'
import { Shop } from '@icons/Shop'
import { SmallCross } from '@icons/SmallCross'
import { Sort } from '@icons/Sort'
import { StarFilled } from '@icons/StarFilled'
import { StarOutline } from '@icons/StarOutline'
import { ThumbsDown } from '@icons/ThumbsDown'
import { ThumbsUp } from '@icons/ThumbsUp'
import { TrashOutline } from '@icons/TrashOutline'
import { Truck } from '@icons/Truck'
import { Twitter } from '@icons/Twitter'
import { User } from '@icons/User'
import { Visa } from '@icons/Visa'

export enum RemoteIconEnums {
  ACCOUNT_NEW = '/icons/account-new.svg',
  ACCOUNT = '/icons/account.svg',
  AE = '/icons/ae.svg',
  ARROW_LEFT = '/icons/arrow-left.svg',
  ARROW_RIGHT = '/icons/arrow-right.svg',
  ARROW = '/icons/arrow.svg',
  CARBON_NEUTRAL = '/icons/carbon-neutral.svg',
  CART_FILLED = '/icons/cart-filled.svg',
  CART_OUTLINE = '/icons/cart-outline.svg',
  CART = '/icons/cart.svg',
  CHECK = '/icons/check.svg',
  CHEVRON_DOWN = '/icons/chevron-down.svg',
  CHEVRON_FILL_DOWN = '/icons/chevron-fill-down.svg',
  CHEVRON_FILL_UP = '/icons/chevron-fill-up.svg',
  CHEVRON_LEFT = '/icons/chevron-left.svg',
  CHEVRON_RIGHT = '/icons/chevron-right.svg',
  CHEVRON_UP = '/icons/chevron-up.svg',
  CLOSE = '/icons/close.svg',
  DESKTOP_LOGO = '/icons/desktop-logo.svg',
  DOUBLE_TICK = '/icons/double-tick.svg',
  EMAIL = '/icons/email.svg',
  ERROR = '/icons/error.svg',
  EYE_OFF = '/icons/eye-off.svg',
  EYE_ON = '/icons/eye-on.svg',
  FACEBOOK_SOLID = '/icons/facebook-solid.svg',
  FACEBOOK = '/icons/facebook.svg',
  FAVOURITE_FILLED = '/icons/favourite-filled.svg',
  FAVOURITE_OUTLINE = '/icons/favourite-outline.svg',
  FAVOURITES = '/icons/favourites.svg',
  FILTER = '/icons/filter.svg',
  FTN_LOGO_FULL = '/icons/ftn-logo-full.svg',
  GIFT = '/icons/gift.svg',
  GOOGLE_FULL = '/icons/google-full.svg',
  GOOGLE = '/icons/google.svg',
  GREY_AMEX = '/icons/grey-amex.svg',
  GREY_EFT = '/icons/grey-eft.svg',
  GREY_MASTERCARD = '/icons/grey-mastercard.svg',
  GREY_OZOW = '/icons/grey-ozow.svg',
  GREY_VISA = '/icons/grey-visa.svg',
  GRID_VIEW = '/icons/grid-view.svg',
  GRID = '/icons/grid.svg',
  HAMBURGER_MENU = '/icons/hamburger-menu.svg',
  HEART_FILLED = '/icons/heart-filled.svg',
  HEART_OUTLINE = '/icons/heart-outline.svg',
  HOME_1 = '/icons/home-1.svg',
  HOME_LOGO = '/icons/home-logo.svg',
  HOME = '/icons/home.svg',
  INSTAGRAM = '/icons/instagram.svg',
  LARGE_CROSS = '/icons/large-cross.svg',
  LIST_VIEW = '/icons/list-view.svg',
  LOCATION = '/icons/location.svg',
  LIST = '/icons/list.svg',
  LOCK_CLOSED_OUTLINE = '/icons/lock-closed-outline.svg',
  LOGO_DESKTOP = '/icons/logo-desktop.svg',
  LOGO_ICON = '/icons/logo-icon.svg',
  LOGO = '/icons/logo.svg',
  MASTERCARD = '/icons/mastercard.svg',
  MINUS = '/icons/minus.svg',
  MOBILE_LOGO = '/icons/mobile-logo.svg',
  OZOW = '/icons/ozow.svg',
  PAYMENT_AMERICAN_EXPRESS_COLOR = '/icons/payment-american-express-color.svg',
  PAYMENT_EFT_COLOR = '/icons/payment-eft-color.svg',
  PAYMENT_MASTER_CARD_COLOR = '/icons/payment-master-card-color.svg',
  PAYMENT_OZOW_COLOR = '/icons/payment-ozow-color.svg',
  PAYMENT_VISA_COLOR = '/icons/payment-visa-color.svg',
  PEACH_PAYMENTS = '/icons/peach-payments.svg',
  PHONE = '/icons/phone.svg',
  PINTEREST = '/icons/pinterest.svg',
  PLUS = '/icons/plus.svg',
  POINTS_OUTLINE = '/icons/points-outline.svg',
  RADIO_ACTIVE = '/icons/radio-active.svg',
  RADION_DEFAULT = '/icons/radion-default.svg',
  SEARCH_OUTLINE = '/icons/search-outline.svg',
  SEARCH = '/icons/search.svg',
  SHARE = '/icons/share.svg',
  SHOP = '/icons/shop.svg',
  SMALL_CROSS = '/icons/small-cross.svg',
  SORT = '/icons/sort.svg',
  STAR_FILLED = '/icons/star-filled.svg',
  STAR_OUTLINE = '/icons/star-outline.svg',
  THUMBS_DOWN = '/icons/thumbs-down.svg',
  THUMBS_UP = '/icons/thumbs-up.svg',
  TRASH_OUTLINE = '/icons/trash-outline.svg',
  TRUCK = '/icons/truck.svg',
  TWITTER = '/icons/twitter.svg',
  USER = '/icons/user.svg',
  VISA = '/icons/visa.svg',
}

export enum LocalIconEnums {
  ACCOUNT_NEW = 'ACCOUNT_NEW',
  ACCOUNT = 'ACCOUNT',
  AE = 'AE',
  ARROW_LEFT = 'ARROW_LEFT',
  ARROW_RIGHT = 'ARROW_RIGHT',
  ARROW = 'ARROW',
  CARBON_NEUTRAL = 'CARBON_NEUTRAL',
  CART_FILLED = 'CART_FILLED',
  CART_OUTLINE = 'CART_OUTLINE',
  CART = 'CART',
  CHECK = 'CHECK',
  CHEVRON_DOWN = 'CHEVRON_DOWN',
  CHEVRON_FILL_DOWN = 'CHEVRON_FILL_DOWN',
  CHEVRON_FILL_UP = 'CHEVRON_FILL_UP',
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  CHEVRON_RIGHT = 'CHEVRON_RIGHT',
  CHEVRON_UP = 'CHEVRON_UP',
  CLOSE = 'CLOSE',
  DESKTOP_LOGO = 'DESKTOP_LOGO',
  DOUBLE_TICK = 'DOUBLE_TICK',
  EMAIL = 'EMAIL',
  ERROR = 'ERROR',
  EYE_OFF = 'EYE_OFF',
  EYE_ON = 'EYE_ON',
  FACEBOOK_SOLID = 'FACEBOOK_SOLID',
  FACEBOOK = 'FACEBOOK',
  FAVOURITE_FILLED = 'FAVOURITE_FILLED',
  FAVOURITE_OUTLINE = 'FAVOURITE_OUTLINE',
  FAVOURITES = 'FAVOURITES',
  FILTER = 'FILTER',
  FTN_LOGO_FULL = 'FTN_LOGO_FULL',
  GIFT = 'GIFT',
  GOOGLE_FULL = 'GOOGLE_FULL',
  GOOGLE = 'GOOGLE',
  GREY_AMEX = 'GREY_AMEX',
  GREY_EFT = 'GREY_EFT',
  GREY_MASTERCARD = 'GREY_MASTERCARD',
  GREY_OZOW = 'GREY_OZOW',
  GREY_VISA = 'GREY_VISA',
  GRID_VIEW = 'GRID_VIEW',
  GRID = 'GRID',
  HAMBURGER_MENU = 'HAMBURGER_MENU',
  HEART_FILLED = 'HEART_FILLED',
  HEART_OUTLINE = 'HEART_OUTLINE',
  HOME_1 = 'HOME_1',
  HOME_LOGO = 'HOME_LOGO',
  HOME = 'HOME',
  INSTAGRAM = 'INSTAGRAM',
  LARGE_CROSS = 'LARGE_CROSS',
  LOCATION = 'LOCATION',
  LIST_VIEW = 'LIST_VIEW',
  LIST = 'LIST',
  LOCK_CLOSED_OUTLINE = 'LOCK_CLOSED_OUTLINE',
  LOGO_DESKTOP = 'LOGO_DESKTOP',
  LOGO_ICON = 'LOGO_ICON',
  LOGO = 'LOGO',
  MASTERCARD = 'MASTERCARD',
  MINUS = 'MINUS',
  MOBILE_LOGO = 'MOBILE_LOGO',
  OZOW = 'OZOW',
  PAYMENT_AMERICAN_EXPRESS_COLOR = 'PAYMENT_AMERICAN_EXPRESS_COLOR',
  PAYMENT_EFT_COLOR = 'PAYMENT_EFT_COLOR',
  PAYMENT_MASTER_CARD_COLOR = 'PAYMENT_MASTER_CARD_COLOR',
  PAYMENT_OZOW_COLOR = 'PAYMENT_OZOW_COLOR',
  PAYMENT_VISA_COLOR = 'PAYMENT_VISA_COLOR',
  PEACH_PAYMENTS = 'PEACH_PAYMENTS',
  PHONE = 'PHONE',
  PINTEREST = 'PINTEREST',
  PLUS = 'PLUS',
  POINTS_OUTLINE = 'POINTS_OUTLINE',
  RADIO_ACTIVE = 'RADIO_ACTIVE',
  RADION_DEFAULT = 'RADION_DEFAULT',
  SEARCH_OUTLINE = 'SEARCH_OUTLINE',
  SEARCH = 'SEARCH',
  SHARE = 'SHARE',
  SHOP = 'SHOP',
  SMALL_CROSS = 'SMALL_CROSS',
  SORT = 'SORT',
  STAR_FILLED = 'STAR_FILLED',
  STAR_OUTLINE = 'STAR_OUTLINE',
  THUMBS_DOWN = 'THUMBS_DOWN',
  THUMBS_UP = 'THUMBS_UP',
  TRASH_OUTLINE = 'TRASH_OUTLINE',
  TRUCK = 'TRUCK',
  TWITTER = 'TWITTER',
  USER = 'USER',
  VISA = 'VISA',
}

export const getIcon = ({ icon, color, hoverColor, className }: { icon: LocalIconEnums, color: string, hoverColor?: string, className?: string }): JSX.Element => {

  switch (icon) {
    case LocalIconEnums.ACCOUNT_NEW:
      return <AccountNew color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.ACCOUNT:
      return <Account color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.AE:
      return <Ae color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.ARROW_LEFT:
      return <ArrowLeft color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.ARROW_RIGHT:
      return <ArrowRight color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.ARROW:
      return <Arrow color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CARBON_NEUTRAL:
      return <CarbonNeutral color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CART_FILLED:
      return <CartFilled color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CART_OUTLINE:
      return <CartOutline color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CART:
      return <Cart color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CHECK:
      return <Check color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CHEVRON_DOWN:
      return <ChevronDown color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CHEVRON_FILL_DOWN:
      return <ChevronFillDown color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CHEVRON_FILL_UP:
      return <ChevronFillUp color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CHEVRON_LEFT:
      return <ChevronLeft color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CHEVRON_RIGHT:
      return <ChevronRight color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CHEVRON_UP:
      return <ChevronUp color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.CLOSE:
      return <Close color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.DESKTOP_LOGO:
      return <DesktopLogo color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.DOUBLE_TICK:
      return <DoubleTick color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.EMAIL:
      return <Email color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.ERROR:
      return <Error color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.EYE_OFF:
      return <EyeOff color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.EYE_ON:
      return <EyeOn color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.FACEBOOK_SOLID:
      return <FacebookSolid color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.FACEBOOK:
      return <Facebook color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.FAVOURITE_FILLED:
      return <FavouriteFilled color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.FAVOURITE_OUTLINE:
      return <FavouriteOutline color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.FAVOURITES:
      return <Favourites color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.FILTER:
      return <Filter color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.FTN_LOGO_FULL:
      return <FtnLogoFull color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.GIFT:
      return <Gift color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.GOOGLE_FULL:
      return <GoogleFull color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.GOOGLE:
      return <Google color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.GREY_AMEX:
      return <GreyAmex color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.GREY_EFT:
      return <GreyEft color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.GREY_MASTERCARD:
      return <GreyMastercard color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.GREY_OZOW:
      return <GreyOzow color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.GREY_VISA:
      return <GreyVisa color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.GRID_VIEW:
      return <GridView color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.GRID:
      return <Grid color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.HAMBURGER_MENU:
      return <HamburgerMenu color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.HEART_FILLED:
      return <HeartFilled color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.HEART_OUTLINE:
      return <HeartOutline color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.HOME_1:
      return <Home_1 color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.HOME_LOGO:
      return <HomeLogo color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.HOME:
      return <Home color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.INSTAGRAM:
      return <Instagram color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.LARGE_CROSS:
      return <LargeCross color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.LOCATION:
      return <Location color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.LIST_VIEW:
      return <ListView color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.LIST:
      return <List color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.LOCK_CLOSED_OUTLINE:
      return <LockClosedOutline color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.LOGO_DESKTOP:
      return <LogoDesktop color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.LOGO_ICON:
      return <LogoIcon color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.LOGO:
      return <Logo color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.MASTERCARD:
      return <Mastercard color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.MINUS:
      return <Minus color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.MOBILE_LOGO:
      return <MobileLogo color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.OZOW:
      return <Ozow color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.PAYMENT_AMERICAN_EXPRESS_COLOR:
      return <PaymentAmericanExpressColor color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.PAYMENT_EFT_COLOR:
      return <PaymentEftColor color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.PAYMENT_MASTER_CARD_COLOR:
      return <PaymentMasterCardColor color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.PAYMENT_OZOW_COLOR:
      return <PaymentOzowColor color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.PAYMENT_VISA_COLOR:
      return <PaymentVisaColor color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.PEACH_PAYMENTS:
      return <PeachPayments color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.PHONE:
      return <Phone color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.PINTEREST:
      return <Pinterest color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.PLUS:
      return <Plus color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.POINTS_OUTLINE:
      return <PointsOutline color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.RADIO_ACTIVE:
      return <RadioActive color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.RADION_DEFAULT:
      return <RadionDefault color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.SEARCH_OUTLINE:
      return <SearchOutline color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.SEARCH:
      return <Search color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.SHARE:
      return <Share color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.SHOP:
      return <Shop color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.SMALL_CROSS:
      return <SmallCross color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.SORT:
      return <Sort color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.STAR_FILLED:
      return <StarFilled color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.STAR_OUTLINE:
      return <StarOutline color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.THUMBS_DOWN:
      return <ThumbsDown color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.THUMBS_UP:
      return <ThumbsUp color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.TRASH_OUTLINE:
      return <TrashOutline color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.TRUCK:
      return <Truck color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.TWITTER:
      return <Twitter color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.USER:
      return <User color={color} hoverColor={hoverColor} className={className} />
    case LocalIconEnums.VISA:
      return <Visa color={color} hoverColor={hoverColor} className={className} />
  }
}
